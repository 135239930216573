<template>
    <div class="login__wrapper">
        <CardItem class="login__block">
            <template #title>
                <img alt="" src="@/assets/logo.png" height="150" />
            </template>
            <template #content>
                <form @submit.prevent="submitForm">
                    <div class="modal__input-block">
                        <span class="p-float-label">
                            <InputText
                                id="login"
                                type="text"
                                v-model="v$.form.username.$model"
                                :class="{
                                    'p-invalid': v$.form.username.$invalid && submitted,
                                }"
                            />
                            <label for="lastname"> <sup>*</sup>Логин</label>
                        </span>
                        <div v-if="v$.form.username.$invalid && submitted">
                            <small v-for="error in v$.form.username.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>
                    <div class="modal__input-block">
                        <span class="p-float-label">
                            <InputText
                                id="password"
                                type="password"
                                v-model="v$.form.password.$model"
                                :class="{
                                    'p-invalid': v$.form.password.$invalid && submitted,
                                }"
                            />
                            <label for="password"> <sup>*</sup>Пароль</label>
                        </span>
                        <div v-if="v$.form.password.$invalid && submitted">
                            <small v-for="error in v$.form.password.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>
                    <div class="login__button">
                        <ButtonItem label="Войти" type="submit" />
                    </div>
                    <!--                    <div class="reset-password">-->
                    <!--                        <router-link :to="{ name: `PasswordRecovery` }">-->
                    <!--                            Забыли пароль?-->
                    <!--                        </router-link>-->
                    <!--                    </div>-->
                </form>
            </template>
        </CardItem>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "LoginItem",
    emits: ["error", "click"],
    data() {
        return {
            submitted: false,
            isFormCorrect: null,
            form: {
                username: null,
                password: null,
            },
        };
    },
    computed: {
        ...mapGetters({ userEmail: "user/userEmail" }),
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
            form: {
                username: {
                    required: helpers.withMessage(
                        "Поле `Тип организации` обязательно к заполнению",
                        required
                    ),
                },
                password: {
                    required: helpers.withMessage(
                        "Поле `Тип организации` обязательно к заполнению",
                        required
                    ),
                },
            },
        };
    },
    methods: {
        ...mapActions(["user/LOGIN"]),
        async submitForm() {
            try {
                const prevUserEmail = this.userEmail;
                const User = new FormData();
                User.append("username", this.form.username);
                User.append("password", this.form.password);
                await this["user/LOGIN"](User);
                if (prevUserEmail !== this.form.username) {
                    this.$emit("click", "success");
                    await this.$router.push("/projects");
                } else {
                    this.$emit("click", "success");
                }
            } catch (e) {
                this.$emit("click", "cancel");
                this.$emit("error", e);
            }
        },
    },
};
</script>

<style scoped></style>
