import { baseURLImg } from "@/api";

export default {
    setDisabledClass(data) {
        return data.disable ? "p-disabled" : "";
    },
    convertDateToString(date) {
        return String(date);
    },
    convertDateToDateTime(date) {
        if (date) {
            return new Date(date);
        } else {
            new Date();
        }
    },
    parsingTypeFile(filename) {
        return filename.split(".")[1];
    },
    getFullPathToImage(path) {
        return `${baseURLImg}/${path}`;
    },
    getParseDate(datetime) {
        if (datetime) {
            const date = new Date(datetime);
            return `${this.checkTime(date.getDate())}.${this.checkTime(
                date.getMonth() + 1
            )}.${date.getFullYear()}`;
        } else {
            return null;
        }
    },
    getParseDateAndTime(datetime) {
        if (datetime) {
            const date = new Date(datetime);
            return `${this.checkTime(date.getDate())}.${this.checkTime(
                date.getMonth() + 1
            )}.${date.getFullYear()} ${this.getLocalHours(date)}:${this.checkTime(
                date.getMinutes()
            )}`;
        } else {
            return null;
        }
    },
    /**
     * Функция возвращает дату в формате "YYYY-MM-DD"
     * @param {Date} date
     * @return {string} дата
     */
    convertDateToJson(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    getLocalHours(date) {
        return this.checkTime(date.getHours());
    },
    checkTime(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    },
    getImagePath(path) {
        if (path) {
            return `${baseURLImg}/${path.name}.${path.ftype}`;
        } else {
            return "";
        }
    },
    getRemarkImagePath(photo) {
        if (photo) {
            return `${baseURLImg}/${photo.filename}.${photo.file_type}`;
        } else {
            return "";
        }
    },
    /**
     * Функция обрезает длинное наименование
     * @example Длинная строка...и ее окончание
     * @param {String} str
     * @param {Number} maxLength
     */
    strStrip(str, maxLength = 50) {
        if (str.length <= maxLength) return str;
        return (
            str.slice(0, Math.floor(maxLength / 2)) + "..." + str.slice(-Math.floor(maxLength / 2))
        );
    },
    findWorkByWorkListId(array, workListId) {
        return array.find((work) => work.work_list_id === workListId);
    },
    findTotalVolumeForWork(works, work_list_id) {
        if (!Array.isArray(works)) return null;
        for (const work of works) {
            if (work.works !== null) {
                const workVolume = this.findTotalVolumeForWork(work.works, work_list_id);
                if (workVolume) return workVolume;
                continue;
            }
            const workListItem = this.findWorkByWorkListId(work.work_list, work_list_id);
            if (workListItem !== undefined) return workListItem;
        }
    },
};
