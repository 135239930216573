import { sendPost, sendGet } from "@/api";

async function logInApi(data) {
    try {
        return await sendPost("login", data);
    } catch (error) {
        console.log("ERROR!!! - logIn", error);
        throw error;
    }
}
async function logOutApi() {
    try {
        return await sendPost("logout");
    } catch (error) {
        console.log("ERROR!!! - logInAPI", error);
        throw error;
    }
}
async function whoAmIApi(data) {
    try {
        return await sendGet("whoami", data);
    } catch (error) {
        console.log("ERROR!!! - whoAmIAPI", error);
        throw error;
    }
}
// Определяет, является ли организация Заказчиком
async function isCustomerRole(organization_id) {
    try {
        return await sendGet(`organization/customer?id_=${organization_id}`);
    } catch (error) {
        console.log("ERROR!!! - isCustomer", error);
        throw error;
    }
}
export { logInApi, logOutApi, whoAmIApi, isCustomerRole };
