import { getRightProjectApi, getTypeRightApi } from "@/api/right";

export default {
    namespaced: true,
    state: {
        workRight: null,
        right: null,
        types: null,
    },
    getters: {
        userRight: (state) => state.right,
        workRight: (state) => state.workRight,
        typesRight: (state) => state.types,
    },
    mutations: {
        SET_RIGHT_PROJECT(state, right) {
            state.right = right;
        },
        SET_WORK_RIGHT(state, right) {
            state.workRight = right;
        },
        SET_TYPES_RIGHT(state, right) {
            state.types = right;
        },
    },
    actions: {
        async GET_RIGHT_WORK({ commit }, data) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getRightProjectApi(
                    data.worker_id,
                    data?.work_id,
                    data.project_id,
                    data.is_for_unplanned_remarks
                );
                await commit("SET_WORK_RIGHT", content);
            } catch (e) {
                throw e;
            }
        },
        async GET_RIGHT_PROJECT({ commit }, data) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getRightProjectApi(
                    data.worker_id,
                    data?.work_id,
                    data.project_id,
                    data.is_for_unplanned_remarks
                );
                await commit("SET_RIGHT_PROJECT", content);
            } catch (e) {
                throw e;
            }
        },
        async GET_TYPES_RIGHT({ commit }) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getTypeRightApi();
                await commit("SET_TYPES_RIGHT", content);
            } catch (e) {
                throw e;
            }
        },
        async UPDATE_RIGHT_PROJECT({ commit, state }, right) {
            let rightIntersection = state.types.filter((x) => right.includes(x.id));
            commit("SET_RIGHT_PROJECT", rightIntersection);
        },
        async RESET_RIGHT_PROJECT({ commit }) {
            commit("SET_RIGHT_PROJECT", null);
        },
    },
};
