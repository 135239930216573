<script setup>
import InvitesTable from "@/modules/Invites/components/InvitesTable.vue";
import { mapMutations } from "@/store/wrapper_store";
import { ref } from "vue";

const { SET_MESSAGE_VIEW } = mapMutations("general");

const current_date = ref("");

const handlerError = (error) => {
    SET_MESSAGE_VIEW({
        message: `Ошибка. Не удалось загрузить данные. ${error} `,
        type: "error",
    });
};
</script>

<template>
    <div class="organization__wrapper-item u-block-group-item">
        <div class="organization__item u-list-data u-component u-list-responsive-scroll">
            <div class="organization__header u-list-header">
                <h2 class="u-title__page">ПРИГЛАШЕНИЯ</h2>
            </div>
            <InvitesTable
                @error="handlerError"
                :key="current_date"
                @reload="current_date = new Date()"
            />
        </div>
    </div>
</template>
