export const typeOrganization = [
    {
        name: "Юридическое лицо",
        id: 1,
    },
    {
        name: "Индивидуальный предприниматель",
        id: 2,
    },
    {
        name: "Физическое лицо",
        id: 3,
    },
];
