import { sendGet } from "@/api";

export async function getPhoneMaskApi() {
    try {
        const response = await sendGet("phone");
        return response;
    } catch (error) {
        console.log("ERROR!!! - getPhoneMaskApi", error);
        throw "Ошибка при загрузке шаблонов для телефонных номеров";
    }
}
