import { helpers, maxLength, minLength, required, sameAs } from "@vuelidate/validators";
import { validatePassword, validateSymbol, validateSymbolAndNumber } from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";

export const useValidation = (employee, password, replayPassword) => {
    const rules = {
        employee: {
            post: {
                required: helpers.withMessage(
                    "Поле 'Должность' обязательно к заполнению",
                    required
                ),
                validateSymbol: helpers.withMessage(
                    "Поле 'Должность' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Должность' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Должность' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
            surname: {
                required: helpers.withMessage("Поле 'Фамилия' обязательно к заполнению", required),
                validateSymbol: helpers.withMessage(
                    "Поле 'Фамилия' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Фамилия' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Фамилия' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
            name: {
                required: helpers.withMessage("Поле 'Имя' обязательно к заполнению", required),
                validateSymbol: helpers.withMessage(
                    "Поле 'Имя' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Имя' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Имя' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
            patronymic: {
                validateSymbol: helpers.withMessage(
                    "Поле 'Отчество' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Отчество' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Отчество' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
            email: {
                required: helpers.withMessage("Поле 'Email' обязательно к заполнению", required),
            },
            number_nrs: {
                required: helpers.withMessage(
                    "Поле 'Идентификационный номер НРС' обязательно к заполнению",
                    required
                ),
                validateSymbolAndNumber: helpers.withMessage(
                    "Поле 'Идентификационный номер НРС' должно содержать буквы",
                    validateSymbolAndNumber
                ),
                minLength: helpers.withMessage(
                    "Поле 'Идентификационный номер НРС' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Идентификационный номер НРС' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
        },
        password: {
            required: helpers.withMessage("Поле 'Пароль' обязательно к заполнению", required),
            validatePassword: helpers.withMessage(
                "В поле 'Пароль' требуется хотя бы одна заглавная буква английского языка, одна строчная, одна цифра и как минимум 8 символов",
                validatePassword
            ),
            minLength: helpers.withMessage(
                "Поле 'Пароль' не должно быть меньше 8 символов",
                minLength(8)
            ),
            maxLength: helpers.withMessage(
                "Поле 'Пароль' не должно превышать 50 символов",
                maxLength(50)
            ),
        },
        replayPassword: {
            required: helpers.withMessage(
                "Поле 'Подтверждение пароля' обязательно к заполнению",
                required
            ),
            sameAsPassword: helpers.withMessage("Пароль не совпадает", sameAs(password)),
            validatePassword: helpers.withMessage(
                "В поле 'Подтверждение пароля' требуется хотя бы одна заглавная буква английского языка, одна строчная, одна цифра и как минимум 8 символов",
                validatePassword
            ),
            minLength: helpers.withMessage(
                "Поле 'Подтверждение пароля' не должно быть меньше 8 символов",
                minLength(8)
            ),
            maxLength: helpers.withMessage(
                "Поле 'Подтверждение пароля' не должно превышать 50 символов",
                maxLength(50)
            ),
        },
    };

    const v$ = useVuelidate(rules, { employee, password, replayPassword });

    return { v$ };
};
