export default [
    {
        path: "/Organization",
        name: "MyOrganization",
        component: () => import("@/pages/OrganizationPage/OrganizationPage.vue"),
        meta: {
            isRoot: true,
            requiresAuth: true,
            layout: "Default",
        },
    },
];
