<template>
    <u-alert />
    <div class="app-content">
        <component :is="layout" />
    </div>
    <div id="modalReAuth"></div>
</template>

<script>
import UAlert from "@/components/Alert";
import AuthLayout from "@/components/Layout/AuthLayout.vue";
import DefaultLayout from "@/components/Layout/DefaultLayout.vue";
import InviteLayout from "@/components/Layout/InviteLayout.vue";

export default {
    components: { UAlert, AuthLayout, DefaultLayout, InviteLayout },
    data() {
        return {
            isOpen: true,
        };
    },
    computed: {
        layout() {
            return this.$route.meta?.layout ? `${this.$route.meta?.layout}Layout` : null;
        },
    },
};
</script>

<style lang="scss">
.app-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &.not-auth {
        height: 100vh;

        .view__content {
            background: -webkit-linear-gradient(180deg, rgb(3, 2, 177), rgb(3, 5, 67));
            background: -moz-linear-gradient(180deg, rgb(3, 2, 177), rgb(3, 5, 67));
            background: linear-gradient(180deg, rgb(3, 2, 177), rgb(3, 5, 67));
        }

        .view__content-auth {
            display: flex;
            align-items: center;
        }
    }

    .invite {
        display: flex;
        justify-content: center;

        .main__content,
        .view__content {
            width: 100%;
            margin: 0;
        }

        .main__content {
            max-width: 900px;
            margin: 0 100px;
        }
    }

    main {
        flex: 1 0 auto;
    }

    .main__content {
        height: 100%;
    }

    .view__content {
        margin: 120px 0 0 240px;
        width: calc(100% - 240px);
        min-height: calc(100vh - 120px);
        background-color: #ffffff;
        transition: 0.2s;
    }

    /* .view__content-full {
        margin: 70px 0 0 60px;
        width: calc(100% - 60px);
        transition: 0.2s;
    } */

    .view__content-auth {
        width: 100%;
        margin: 0;
        height: 100%;
        min-height: auto;
        padding: 0 100px;
    }
}
</style>
