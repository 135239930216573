import { helpers, maxLength, minLength, numeric, required } from "@vuelidate/validators";

/**
 * Получение шаблона объекта для валидации
 * @param {number} orgType - тип организации
 */
export const getValidationTemplate = (orgType) => {
    if (orgType === 1) {
        return {
            msrn: {
                required: helpers.withMessage("Поле 'ОГРН' обязательно к заполнению", required),
                numeric: helpers.withMessage("В поле 'ОГРН' могут быть только цифры", numeric),
                minLength: helpers.withMessage(
                    "Поле 'ОГРН' не должно быть меньше 13 символов",
                    minLength(13)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'ОГРН' не должно превышать 13 символов",
                    maxLength(13)
                ),
            },
            tin: {
                required: helpers.withMessage("Поле 'ИНН' обязательно к заполнению", required),
                numeric: helpers.withMessage("В поле 'ИНН' могут быть только цифры", numeric),
                minLength: helpers.withMessage(
                    "Поле 'ИНН' не должно быть меньше 10 символов",
                    minLength(10)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'ИНН' не должно превышать 10 символов",
                    maxLength(10)
                ),
            },
        };
    }

    if (orgType === 2) {
        return {
            msrnie: {
                required: helpers.withMessage("Поле 'ОГРНИП' обязательно к заполнению", required),
                numeric: helpers.withMessage("В поле 'ОГРНИП' могут быть только цифры", numeric),
                minLength: helpers.withMessage(
                    "Поле 'ОГРНИП' не должно быть меньше 15 символов",
                    minLength(15)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'ОГРНИП' не должно превышать 15 символов",
                    maxLength(15)
                ),
            },
            tin: {
                required: helpers.withMessage("Поле 'ИНН' обязательно к заполнению", required),
                numeric: helpers.withMessage("В поле 'ИНН' могут быть только цифры", numeric),
                minLength: helpers.withMessage(
                    "Поле 'ИНН' не должно быть меньше 12 символов",
                    minLength(12)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'ИНН' не должно превышать 12 символов",
                    maxLength(12)
                ),
            },
        };
    }

    return {};
};
