<template>
    <main class="invite">
        <div class="main__content">
            <div class="view__content">
                <router-view />
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: "AuthLayout",
};
</script>

<style scoped></style>
