<template>
    <ToastItem />
</template>

<script>
export default {
    name: "u-alert",
    computed: {
        getMessageView() {
            return this.$store.getters["general/getMessageView"];
        },
    },
    watch: {
        getMessageView(newMessage) {
            this.$toast.add({
                severity: newMessage?.type,
                summary: newMessage.summary,
                detail: newMessage?.message,
                life: 7000,
            });
        },
    },
};
</script>

<style scoped></style>
