export default [
    {
        path: "/employees",
        name: "MyEmployees",
        component: () => import("@/pages/EmployeesPage/EmployeesPage.vue"),
        meta: {
            isRoot: true,
            requiresAuth: true,
            layout: "Default",
        },
    },
];
