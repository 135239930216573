<template>
    <DialogItem
        class="modal__width"
        :header="`Список документов`"
        v-model:visible="displayModal"
        :modal="true"
        :closable="false"
    >
        <h3>Разрешительная документация</h3>
        <LoaderItem v-if="loading" />
        <div v-else class="doc_list_wrap">
            <div class="doc_list_company">
                <div class="doc_list_company_info">
                    <AvatarItem
                        :data-props="organizationData"
                        :data-avatar-props="organizationData.OrganizationPhoto"
                    />
                    <div class="doc_list_name__company">
                        {{ organizationData.Organization.name }}
                    </div>
                </div>
                <div v-if="isMyOrganization() && isIAmManager()">
                    <ButtonItem
                        label="Загрузить документ"
                        @click="displayModalUpload = true"
                        icon="pi pi-briefcase"
                    />
                </div>
            </div>
            <ul class="doc_list_items">
                <li v-for="(doc, index) in docList" :key="index">
                    <div class="doc_list_item_filename">
                        <i class="pi pi-file"></i>
                        <span>{{ doc.OrganizationDocs.filename }}</span>
                    </div>
                    <div>
                        <ButtonItem
                            v-tooltip.top="'Скачать'"
                            :loading="fileLoadingId === doc.OrganizationDocs.file_id"
                            icon="pi pi-download"
                            @click="handlerDownloadFile(doc)"
                        />
                    </div>
                </li>
            </ul>
        </div>
        <div class="modal__footer">
            <ButtonItem
                label="Отменить"
                icon="pi pi-times"
                @click="closeModal"
                class="p-button-text"
            />
        </div>
    </DialogItem>
    <FileUploadDocsModal
        v-if="displayModalUpload && isMyOrganization() && isIAmManager()"
        :isActive="displayModalUpload"
        :organization-data="organizationData.Organization"
        @submitHandler="handlerSubmitLoadDocuments"
        @close="displayModalUpload = false"
        @error="$emit('error', $event)"
    />
</template>

<script setup>
import { onMounted, ref, toRefs } from "vue";
import { mapGetters } from "@/store/wrapper_store";
import rightHelper from "@/utils/rightHelper";
import { getOrganizationDocumentsApi } from "@/modules/ListDocuments/api/getOrganizationDocumentsApi";
import { uploadOrganizationDocumentsApi } from "@/modules/ListDocuments/api/uploadOrganizationDocumentsApi";
import { getFileApi } from "@/api/filesDocs";
import LoaderItem from "@/components/Loader/index.vue";
import AvatarItem from "@/components/Avatar/index.vue";
import FileUploadDocsModal from "@/modules/ListDocuments/components/FileUploadDocsModal/FileUploadDocsModal.vue";

const emit = defineEmits(["close", "error", "submitHandler"]);
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
    organizationData: {
        type: Object,
        default: null,
    },
    organization_id: {
        type: Number || String,
        default: null,
    },
});

const { isActive, organizationData, organization_id } = toRefs(props);
const { stateUser } = mapGetters("user");
const docList = ref([]);
const displayModal = ref(false);
const displayModalUpload = ref(false);
const loading = ref(true);
const fileLoadingId = ref(null);

onMounted(async () => {
    try {
        displayModal.value = isActive.value;
        docList.value = await getOrganizationDocumentsApi(organization_id.value);
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
});

const closeModal = () => emit("close");

const isMyOrganization = () => organization_id.value === stateUser.value.organization;

const isIAmManager = () => rightHelper.isIAmManager(stateUser.value);

const handlerDownloadFile = async (document) => {
    if (document?.OrganizationDocs) {
        try {
            fileLoadingId.value = document.OrganizationDocs.file_id;
            await getFileApi(document.OrganizationDocs, document.ftype);
        } catch (e) {
            emit("error", "Нет информации о файле");
        } finally {
            fileLoadingId.value = null;
        }
    }
};

const handlerSubmitLoadDocuments = async (formData) => {
    loading.value = true;
    await uploadOrganizationDocumentsApi(formData);
    docList.value = await getOrganizationDocumentsApi(organization_id.value);
    loading.value = false;
};
</script>

<style lang="scss" scoped>
h3 {
    text-align: center;
}

.doc_list_wrap {
    padding: 1rem 3rem;

    .doc_list_company {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .doc_list_company_info {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .doc_list_name__company {
            margin-left: 1rem;
            margin-right: 1rem;

            span {
                display: block;
            }
        }
    }

    .doc_list_items {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 1rem;
            margin-bottom: 1rem;
            background-color: #ced3e4;
            padding: 0.5rem 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .doc_list_item_filename {
            i {
                margin-right: 1rem;
            }
        }
    }
}
</style>
