export const navigation = [
    {
        label: "Моя компания",
        icon: "pi pi-fw pi-briefcase",
        role: "all",
        to: "/Organization",
    },
    {
        label: "Мои сотрудники",
        icon: "pi pi-fw pi-users",
        role: "all",
        to: "/employees",
    },
    {
        label: "Проекты",
        icon: "pi pi-fw pi-id-card",
        role: "all",
        to: "/projects",
    },
    {
        label: "Приглашения",
        icon: "pi pi-fw pi-id-card",
        role: "manager",
        to: "/invites",
    },
    {
        label: "Шаблоны",
        icon: "pi pi-fw pi-file",
        role: "all",
        to: "/templates",
    },
    {
        label: "Лицензии",
        icon: "pi pi-fw pi-key",
        role: "all",
        to: "/licenses",
    },
    // {
    //     label: "Gantt",
    //     icon: "pi pi-fw pi-file",
    //     role: "all",
    //     to: "/test",
    // },
];
