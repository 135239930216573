<template>
    <LoaderItem v-if="loading" />
    <div v-else class="u-block-table">
        <DataTableItem
            :value="dataEmployeesRef"
            :paginator="true"
            :rows="10"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 20, 50]"
            currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}"
            responsiveLayout="scroll"
            paginatorPosition="top"
            :pageLinkSize="pages"
            :rowClass="stateEmployee"
            class="u-table"
            @sort="handlerSort"
            :sort-field="sort.sortField"
            :sort-order="sort.sortOrder"
        >
            <!--            <TemplatePage #paginatorstart>-->
            <!--                <span class="table__title">Сотрудники</span>-->
            <!--            </TemplatePage>-->
            <ColumnItem>
                <template #body="slotProps">
                    <AvatarItem
                        :data-props="slotProps.data"
                        :data-avatar-props="slotProps.data.WorkerPhoto"
                    />
                </template>
            </ColumnItem>
            <ColumnItem header="ФИО" :sortable="true" field="fio">
                <template #body="{ data }">
                    <SkeletonItem v-if="loading" />
                    {{ data.fio }}
                </template>
            </ColumnItem>
            <ColumnItem header="Идентификационный номер НРС" :sortable="true" field="number_nrs">
                <template #body="slotProps">
                    <SkeletonItem v-if="loading" />
                    {{ slotProps.data.number_nrs }}
                </template>
            </ColumnItem>
            <ColumnItem header="Документы" field="protocol" :style="{ width: '150px' }">
                <template #body="slotProps">
                    <SkeletonItem v-if="loading" />
                    <ButtonItem
                        v-else
                        :disabled="slotProps.data.disable"
                        v-tooltip.top="'Просмотреть список документов'"
                        icon="pi pi-file"
                        @click="handlerViewingDocumentList(slotProps.data)"
                    />
                </template>
            </ColumnItem>
            <ColumnItem :sortable="false">
                <template #body="slotProps">
                    <SkeletonItem v-if="loading" />
                    <template v-else>
                        <div class="button__actions button__group">
                            <ButtonItem
                                :disabled="slotProps.data.disable"
                                v-tooltip.top="'Просмотреть'"
                                class="p-button-blue"
                                icon="pi pi-eye"
                                @click="handlerViewingEmployee(slotProps.data)"
                            />
                            <template v-if="isMyOrganization() && isIAmManager()">
                                <ButtonItem
                                    v-tooltip.top="'Редактировать'"
                                    :disabled="slotProps.data.disable"
                                    icon="pi pi-pencil"
                                    class="p-button-blue"
                                    @click="handlerShowModalEditEmployee(slotProps.data)"
                                />
                                <ButtonItem
                                    v-if="!slotProps.data.disable && !isMyProfile(slotProps.data)"
                                    class="p-button-danger"
                                    v-tooltip.top="'Заблокировать'"
                                    icon="pi pi-lock"
                                    @click="confirmBlock(slotProps.data)"
                                />
                                <ButtonItem
                                    v-if="slotProps.data.disable && !isMyProfile(slotProps.data)"
                                    class="p-button-danger"
                                    v-tooltip.top="'Разблокировать'"
                                    icon="pi pi-lock-open"
                                    @click="confirmUnblock(slotProps.data)"
                                />
                            </template>
                        </div>
                    </template>
                </template>
            </ColumnItem>
            <template #footer>
                <ButtonItem
                    v-if="isMyOrganization() && isIAmManager()"
                    @click="showModal('addModal')"
                    label="Добавить сотрудника"
                    icon="pi pi-briefcase"
                />
            </template>
        </DataTableItem>
        <ConfirmDialog style="max-width: 90%" />
        <ListEmployeeDocumentsModal
            v-if="displayModal === 'documentListModal'"
            :isActive="displayModal === 'documentListModal'"
            :profile-data="selectProfile"
            :organization_id="organization_id"
            @close="closeModal"
            @error="$emit('error', $event)"
        />
        <template v-if="isMyOrganization() && isIAmManager()">
            <InviteEmployeeModal
                v-if="displayModal === 'addModal'"
                :isActive="displayModal === 'addModal'"
                :organization_id="organization_id"
                @close="closeModal"
                @submitHandler="handlerInviteEmployee"
            />
            <EditProfileForm
                v-if="displayModal === 'editModal'"
                :isActive="displayModal === 'editModal'"
                :profile-data="selectProfile"
                :is-i-am-manager="isIAmManager()"
                :is-my-organization="isMyOrganization()"
                :is-my-profile="isMyProfile(selectProfile)"
                @close="closeModal"
                @submitHandler="handlerEditProfile"
                @handlerDeletePhoto="handlerDeletePhoto"
            />
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref, toRefs } from "vue";
import { useIsMobile } from "@/hooks/useIsMobile";
import { useConfirmBlock } from "@/modules/Employees/components/EmployeesList/hooks/useConfirmBlock";
import { dataEmployees } from "@/data/base/employees";
import {
    deleteWorkerPhotoAPI,
    editProfileEmployeeApi,
    getEmployeesOrganizationApi,
    newInviteApi,
} from "@/api/employee";
import { useRoute, useRouter } from "vue-router";
import { mapActions, mapGetters } from "@/store/wrapper_store";
import rightHelper from "@/utils/rightHelper";
import utils from "@/utils/helper";
import LoaderItem from "@/components/Loader/index.vue";
import AvatarItem from "@/components/Avatar/index.vue";
import InviteEmployeeModal from "@/modules/Employees/components/InviteEmployeeModal/InviteEmployeeModal.vue";
import { ListEmployeeDocumentsModal } from "@/modules/ListDocuments";
import { EditProfileForm } from "@/modules/EditProfile";

const emit = defineEmits(["error"]);
const props = defineProps(["organization_id"]);
const { organization_id } = toRefs(props);
const route = useRoute();
const router = useRouter();
const { pages } = useIsMobile();

const { stateUser } = mapGetters("user");
const { UPDATE_USER } = mapActions("user");
const dataEmployeesRef = ref(dataEmployees);
const loading = ref(true);
const selectProfile = ref(null);
const sort = ref({
    sortField: "fio",
    sortOrder: 1,
});
const displayModal = ref("");

const getEmployeesData = async () => {
    const { content } = await getEmployeesOrganizationApi(organization_id.value);
    return content;
};

const { confirmBlock, confirmUnblock } = useConfirmBlock(
    loading,
    dataEmployeesRef,
    getEmployeesData,
    emit
);

onMounted(async () => {
    try {
        dataEmployeesRef.value = await getEmployeesData();
        dataEmployeesRef.value.forEach((employee) => {
            employee.fio = `${employee.surname ? employee.surname : ""} ${employee.name} ${
                employee.patronymic ? employee.patronymic : ""
            }`;
        });
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
});

const handlerSort = (e) => {
    sort.value.sortOrder = e.sortOrder;
    sort.value.sortField = e.sortField;
};

const showModal = (nameModal) => (displayModal.value = nameModal);

const closeModal = () => (displayModal.value = "");

const isMyProfile = (profile) => stateUser.value.id === profile.id;

const isMyOrganization = () => organization_id.value === stateUser.value.organization;

const isIAmManager = () => rightHelper.isIAmManager(stateUser.value);

const stateEmployee = (data) => utils.setDisabledClass(data);

const handlerViewingDocumentList = (data) => {
    selectProfile.value = { ...data };
    showModal("documentListModal");
};

const handlerShowModalEditEmployee = (data) => {
    selectProfile.value = { ...data };
    showModal("editModal");
};

const handlerInviteEmployee = async (employee) => {
    try {
        loading.value = true;
        await newInviteApi(employee, true);
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
};

const handlerViewingEmployee = (data) => {
    let nameRoute;

    if (route.params.project_id ** route.params.organization_id) {
        nameRoute = "ProjectItemOrganizationEmployeeProfile";
    } else {
        nameRoute = "ProfileEmployeeMyOrganization";
    }

    router.push({
        name: nameRoute,
        params: {
            employee_id: data.id,
        },
    });
};

const handlerDeletePhoto = async (profile) => {
    try {
        loading.value = true;
        await deleteWorkerPhotoAPI(profile.id);
        dataEmployeesRef.value = await getEmployeesData();
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
};

const handlerEditProfile = async (profile) => {
    try {
        loading.value = true;
        await editProfileEmployeeApi(profile);
        dataEmployeesRef.value = await getEmployeesData();

        if (isMyProfile(selectProfile.value)) {
            const myProfile = dataEmployeesRef.value.find((employee) => {
                return employee.id === selectProfile.value.id;
            });

            await UPDATE_USER(myProfile);
        }
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
};
</script>
