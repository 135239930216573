import { sendPut } from "@/api";
import store from "@/store";

export async function editOrganizationApi(formData) {
    try {
        const response = await sendPut("organization/update", formData);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Компания отредактирована",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - editOrganizationApi", error);
        throw error;
    }
}
