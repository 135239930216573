import store from "@/store";
import { sendDelete, sendGet, sendPost, sendPut } from "@/api";

async function newInviteApi(data, employeeText) {
    try {
        const response = await sendPost("invite", data);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: `${employeeText ? "Сотруднику" : "Компании"} отправлено приглашение`,
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - newInviteApi", error);
        throw error;
    }
}

async function inviteOrganizationApi(data) {
    try {
        const response = await sendPost("invite/exist_organization", data);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Компании отправлено приглашение",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - inviteOrganizationApi", error);
        throw error;
    }
}

async function getEmployeeDocumentsApi(id) {
    try {
        const { content } = await sendGet(`worker/worker_docs?worker_id=${id}`);
        return content;
    } catch (error) {
        console.log("ERROR!!! - getEmployeeDocumentsApi", error);
        throw error;
    }
}

async function uploadEmployeeDocumentsApi(documentsInfo) {
    try {
        console.log(documentsInfo);
        return await sendPost("worker/upload_doc", documentsInfo);
    } catch (error) {
        console.log("ERROR!!! - uploadEmployeeDocumentsApi", error);
        throw error;
    }
}

async function getEmployeeApi(id) {
    try {
        return await sendGet(`worker?id_=${id}`);
    } catch (error) {
        console.log("ERROR!!! - getEmployeeApi", error);
        throw error;
    }
}

async function getEmployeesOrganizationApi(id) {
    try {
        return await sendGet(`worker/all?organization=${id}`);
    } catch (error) {
        console.log("ERROR!!! - getEmployeesOrganizationApi", error);
        throw error;
    }
}

async function getEmployeesProjectApi(project_id, organization_id) {
    try {
        return await sendGet(`worker/all?project=${project_id}&organization=${organization_id}`);
    } catch (error) {
        console.log("ERROR!!! - getEmployeesProjectApi", error);
        throw error;
    }
}

async function editProfileEmployeeApi(profile) {
    try {
        const response = await sendPut("worker/update", profile);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Данные отредактированы",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - editProfileEmployeeApi", error);
        throw error;
    }
}

async function getFreeProjectWorkersAPI(org_id, project_id) {
    try {
        return await sendGet(`/worker/free_project?organization=${org_id}&project=${project_id}`);
    } catch (error) {
        console.log("ERROR!!! - getFreeProjectWorkersAPI", error);
        throw error;
    }
}

async function getAllWorkersWithWorksAPI(project_id, org_id) {
    try {
        return await sendGet(`/worker/with_work?project=${project_id}&organization=${org_id}`);
    } catch (error) {
        console.log("ERROR!!! - getAllWorkersWithWorksAPI", error);
        throw error;
    }
}

async function getWorkersWithWorksByWorkListAPI(project_id, org_id, work_list_id) {
    try {
        return await sendGet(
            `/worker/with_work?project=${project_id}&organization=${org_id}&work_list=${work_list_id}`
        );
    } catch (error) {
        console.log("ERROR!!! - getWorkersWithWorksByWorkListAPI", error);
        throw error;
    }
}

async function deleteWorkerPhotoAPI(worker_id) {
    try {
        return await sendDelete(`/worker/worker_photo?worker_id=${worker_id}`);
    } catch (error) {
        console.log("ERROR!!! - deleteWorkerPhotoAPI", error);
        throw error;
    }
}

export {
    getWorkersWithWorksByWorkListAPI,
    getAllWorkersWithWorksAPI,
    getFreeProjectWorkersAPI,
    newInviteApi,
    editProfileEmployeeApi,
    getEmployeesOrganizationApi,
    getEmployeesProjectApi,
    getEmployeeApi,
    getEmployeeDocumentsApi,
    uploadEmployeeDocumentsApi,
    deleteWorkerPhotoAPI,
    inviteOrganizationApi,
};
