import { getNotificationsByProject } from "@/api/notifications";

export default {
    namespaced: true,
    state: {
        buildControlNotifications: null,
        actsNotifications: null,
    },
    getters: {
        buildControlNotifications: (state) => state.buildControlNotifications,
        actsNotifications: (state) => state.actsNotifications,
    },
    mutations: {
        SET_BUILD_CONTROL(state, notifications) {
            state.buildControlNotifications = notifications;
        },
        SET_ACTS_NOTIFICATIONS(state, notifications) {
            state.actsNotifications = notifications;
        },
    },
    actions: {
        async GET_PROJECT_TABS_NOTIFICATIONS({ commit }, data) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getNotificationsByProject(
                    data.organization_id,
                    data.worker_id,
                    data.project_id
                );

                await commit("SET_ACTS_NOTIFICATIONS", {
                    acts: content.acts,
                    requests: content.requests,
                });
                await commit("SET_BUILD_CONTROL", content.build_control);
            } catch (e) {
                throw e;
            }
        },
        CHANGE_ACTS_NOTIFICATIONS({ commit }, data) {
            commit("SET_ACTS_NOTIFICATIONS", data);
        },
        CHANGE_BUILD_CONTROL_NOTIFICATIONS({ commit }, data) {
            commit("SET_BUILD_CONTROL", data);
        },
    },
};
