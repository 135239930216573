import { getRoleApi, getTypeRoleApi } from "@/api/role";

//"id": 1,
//"name": "Заказчик"
//
//"id": 2,
//"name": "Генеральный Подрядчик"
//
//"id": 3,
//"name": "Подрядчик"
//
//"id": 4,
//"name": "Проектировщик"

export default {
    namespaced: true,
    state: {
        role: null,
        types: null,
    },
    getters: {
        userRole: (state) => state.role,
        typesRole: (state) => state.types,
    },
    mutations: {
        SET_ROLE(state, role) {
            state.role = role;
        },
        SET_TYPES_ROLE(state, type) {
            state.types = type;
        },
    },
    actions: {
        async GET_ROLE({ commit }, data) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getRoleApi(data.organization_id, data.project_id);
                await commit("SET_ROLE", content);
            } catch (e) {
                throw e;
            }
        },
        async GET_TYPES_ROLE({ commit }) {
            // eslint-disable-next-line no-useless-catch
            try {
                let { content } = await getTypeRoleApi();
                await commit("SET_TYPES_ROLE", content);
            } catch (e) {
                throw e;
            }
        },
        async UPDATE_ROLE({ commit }, role) {
            commit("SET_ROLE", role);
        },
        async RESET_ROLE({ commit }) {
            commit("SET_ROLE", null);
        },
    },
};
