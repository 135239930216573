export default [
    {
        path: "/invites",
        name: "InvitesPage",
        component: () => import("@/pages/InvitesPage/InvitesPage.vue"),
        meta: {
            isRoot: true,
            requiresAuth: true,
            layout: "Default",
        },
    },
];
