import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { validateSymbol, validateSymbolAndNumber } from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";
import { numWord } from "@/utils/declensionWord";
import { getValidationTemplate } from "@/modules/Organization/components/CardOrganizationForm/utils";

export const useValidation = (organization, address, phoneMask, orgType) => {
    const rulesTemplate = getValidationTemplate(orgType.value);

    const rules = {
        organization: {
            name: {
                required: helpers.withMessage(
                    "Поле 'Наименование компании' обязательно к заполнению",
                    required
                ),
                validateSymbol: helpers.withMessage(
                    "Поле 'Наименование компании' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Наименование компании' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Наименование компании' не должно превышать 100 символов",
                    maxLength(100)
                ),
            },
            ...rulesTemplate,
            phone_template_id: {
                required: helpers.withMessage(
                    "Поле 'Шаблон для номера телефона по стране' обязательно к заполнению",
                    required
                ),
            },
            phone: {
                required: helpers.withMessage("Поле 'Телефон' обязательно к заполнению", required),
                maxLength: helpers.withMessage(
                    `Поле 'Телефон' не должно превышать ${phoneMask.value.length} ${numWord(
                        phoneMask.value.length,
                        ["символа", "символов"]
                    )}`,
                    maxLength(phoneMask.value.length)
                ),
                minLength: helpers.withMessage(
                    "Поле 'Телефон' заполнено частично",
                    minLength(phoneMask.value.length)
                ),
            },
            fax: {
                maxLength: helpers.withMessage(
                    `Поле 'Факс' не должен превышать ${phoneMask.value.length} ${numWord(
                        phoneMask.value.length,
                        ["символа", "символов"]
                    )}`,
                    maxLength(phoneMask.value.length)
                ),
                minLength: helpers.withMessage(
                    "Поле 'Факс' заполнено частично",
                    minLength(phoneMask.value.length)
                ),
            },
            state_regis: {
                required: helpers.withMessage(
                    "Поле 'Номер свидетельства о государственной регистрации' обязательно к заполнению",
                    required
                ),
                minLength: helpers.withMessage(
                    "Поле 'Номер свидетельства о государственной регистрации' не должно быть меньше 1 символов",
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Номер свидетельства о государственной регистрации' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
            state_regis_date: {
                required: helpers.withMessage(
                    "Поле 'Дата выдачи свидетельства о государственной регистрации' обязательно к заполнению",
                    required
                ),
            },
        },
        address: {
            postal_code: {
                required: helpers.withMessage(
                    "Поле 'Почтовый индекс' обязательно к заполнению",
                    required
                ),
                minLength: helpers.withMessage(
                    "Поле 'Почтовый индекс' не должно быть меньше 6 символов",
                    minLength(6)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Почтовый индекс' не должно превышать 6 символов",
                    maxLength(6)
                ),
            },
            city: {
                required: helpers.withMessage("Поле 'Город' обязательно к заполнению", required),
                validateSymbol: helpers.withMessage(
                    "Поле 'Город' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Город' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Город' не должно превышать 100 символов",
                    maxLength(100)
                ),
            },
            area: {
                required: helpers.withMessage("Поле 'Область' обязательно к заполнению", required),
                validateSymbol: helpers.withMessage(
                    "Поле 'Область' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Область' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Область' не должно превышать 100 символов",
                    maxLength(100)
                ),
            },
            street: {
                required: helpers.withMessage("Поле 'Улица' обязательно к заполнению", required),
                validateSymbol: helpers.withMessage(
                    "Поле 'Улица' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Улица' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Улица' не должно превышать 100 символов",
                    maxLength(100)
                ),
            },
            house: {
                required: helpers.withMessage("Поле 'Дом' обязательно к заполнению", required),
                minLength: helpers.withMessage(
                    "Поле 'Дом' не должно быть меньше 1 символов",
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Дом' не должно превышать 4 символов",
                    maxLength(4)
                ),
            },
            building: {
                validateSymbolAndNumber: helpers.withMessage(
                    "Поле 'Строение/корпус' должно содержать буквы",
                    validateSymbolAndNumber
                ),
                minLength: helpers.withMessage(
                    "Поле 'Строение/корпус' не должно быть меньше 1 символов",
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Строение/корпус' не должно превышать 20 символов",
                    maxLength(20)
                ),
            },
            office: {
                minLength: helpers.withMessage(
                    "Поле 'Офис' не должно быть меньше 1 символов",
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Офис' не должно превышать 20 символов",
                    maxLength(20)
                ),
            },
        },
    };

    const state = {
        organization,
        address,
    };

    const v$ = useVuelidate(rules, state);

    return { v$ };
};
