import { sendDelete } from "@/api";

export async function deleteOrganizationPhotoAPI(organization_id) {
    try {
        return await sendDelete(
            `/organization/organization_photo?organization_id=${organization_id}`
        );
    } catch (error) {
        console.log("ERROR!!! - deleteOrganizationPhotoAPI", error);
        throw error;
    }
}
