<template>
    <DialogItem
        class="modal__width"
        :header="`Редактирование сотрудника`"
        v-model:visible="displayModal"
        :modal="true"
        :closable="false"
    >
        <form @submit.prevent="submitForm">
            <div class="modal__input-block">
                <span class="p-float-label">
                    <AvatarItem
                        :data-avatar-props="profile.WorkerPhoto"
                        :data-props="profile"
                        :type-mode="'write'"
                        @deletePhoto="handlerDeletePhoto"
                        @selectFile="handlerSelectFile"
                        @removeFile="handlerRemoveFile"
                        @clearFile="handlerClearFile"
                        :key="profile.WorkerPhoto.name"
                    />
                </span>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="post"
                        type="text"
                        v-maska:[optionsMask.spaceMask()]
                        v-model="v$.profile.post.$model"
                        :class="{
                            'p-invalid': v$.profile.post.$invalid && submitted,
                        }"
                    />
                    <label for="post"> <sup>*</sup>Должность</label>
                </span>
                <div v-if="v$.profile.post.$invalid && submitted">
                    <small v-for="error in v$.profile.post.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="surname"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.profile.surname.$model"
                        :class="{
                            'p-invalid': v$.profile.surname.$invalid && submitted,
                        }"
                    />
                    <label for="surname"> <sup>*</sup>Фамилия</label>
                </span>
                <div v-if="v$.profile.surname.$invalid && submitted">
                    <small v-for="error in v$.profile.surname.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="name"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.profile.name.$model"
                        :class="{
                            'p-invalid': v$.profile.name.$invalid && submitted,
                        }"
                    />
                    <label for="name"> <sup>*</sup>Имя</label>
                </span>
                <div v-if="v$.profile.name.$invalid && submitted">
                    <small v-for="error in v$.profile.name.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="patronymic"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.profile.patronymic.$model"
                        :class="{
                            'p-invalid': v$.profile.patronymic.$invalid && submitted,
                        }"
                    />
                    <label for="patronymic">Отчество</label>
                </span>
                <div v-if="v$.profile.patronymic.$invalid && submitted">
                    <small v-for="error in v$.profile.patronymic.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="email"
                        type="text"
                        readonly
                        disabled
                        v-model="v$.profile.email.$model"
                        :class="{
                            'p-invalid': v$.profile.email.$invalid && submitted,
                        }"
                    />
                    <label for="email"> <sup>*</sup>Email</label>
                </span>
                <div v-if="v$.profile.email.$invalid && submitted">
                    <small v-for="error in v$.profile.email.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="number_nrs"
                        type="text"
                        v-model="v$.profile.number_nrs.$model"
                        :class="{
                            'p-invalid': v$.profile.number_nrs.$invalid && submitted,
                        }"
                    />
                    <label for="number_nrs"> <sup>*</sup>Идентификационный номер НРС</label>
                </span>
                <div v-if="v$.profile.number_nrs.$invalid && submitted">
                    <small v-for="error in v$.profile.number_nrs.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block" v-if="isMyOrganization && isIAmManager && !isMyProfile">
                <span class="p-float-label">
                    <CheckboxItem
                        id="checkbox_manager"
                        class="custom-checkbox"
                        v-model="profile.manager"
                        :binary="true"
                    />
                    <label for="checkbox_manager"> Управляющий</label>
                </span>
            </div>
            <DividerItem />
            <div class="modal__footer">
                <ButtonItem
                    label="Отмена"
                    icon="pi pi-times"
                    @click="closeModal"
                    class="p-button-text"
                />
                <ButtonItem label="Сохранить" icon="pi pi-check" type="submit" />
            </div>
        </form>
    </DialogItem>
</template>

<script setup>
import { ref, toRefs } from "vue";
import { useValidation } from "@/modules/EditProfile/components/EditProfileForm/hooks/useValidation";
import { cloneDeep } from "lodash";
import masks from "@/utils/masks";
import AvatarItem from "@/components/Avatar/index.vue";

const emit = defineEmits(["close", "submitHandler", "handlerDeletePhoto"]);
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
    isMyOrganization: {
        type: Boolean,
        default: false,
    },
    isIAmManager: {
        // является ли авторизованный пользователь управляющим
        type: Boolean,
        default: false,
    },
    isMyProfile: {
        type: Boolean,
        default: false,
    },
    profileData: {
        type: Object,
        default: () => {},
    },
});

const { isActive, isMyOrganization, isIAmManager, isMyProfile, profileData } = toRefs(props);
const profile = ref(cloneDeep(profileData.value));
const submitted = ref(false);
const displayModal = ref(isActive.value);
const isFormCorrect = ref(null);
const optionsMask = ref(masks);

const { v$ } = useValidation(profile);

const handlerDeletePhoto = (profile) => {
    emit("handlerDeletePhoto", profile);
};

const closeModal = () => {
    submitted.value = false;
    emit("close");
};

/**
 * обработчик выбора файла, записывает объект файла в file
 * @param event - событие выбора файла
 */
const handlerSelectFile = (event) => {
    profile.value.file = event.files[0];
};

const handlerRemoveFile = (event) => {
    profile.value.file = event.files;
};

const handlerClearFile = () => {
    profile.value.file = [];
};

const submitForm = async () => {
    submitted.value = true;
    isFormCorrect.value = await v$.value.$validate();

    if (isFormCorrect.value) {
        const formData = createFormData(profile.value);
        emit("submitHandler", formData);
        emit("close");
    }
};

const createFormData = (fileData) => {
    const formData = new FormData();

    if (fileData.file) {
        formData.append("files", fileData.file);
    }

    formData.append("worker", JSON.stringify(fileData));

    return formData;
};
</script>
