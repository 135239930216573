import { sendGet } from "@/api";

export async function getInvitesForOrganizationAPI(invited_org_id) {
    try {
        const { content } = await sendGet(
            `invite/invites_for_exist_organization?invited_org_id=${invited_org_id}`
        );
        return content;
    } catch (error) {
        console.log("ERROR!!! - getInvitesForOrganizationAPI", error);
        throw error;
    }
}
