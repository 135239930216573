<template>
    <div class="u-avatar-block">
        <div class="u-avatar-item" :style="getStyle()">
            <div class="u-avatar">
                <img v-if="dataAvatarProps?.name" :src="getImagePath(dataAvatarProps)" />
                <span v-else>
                    {{ getFirstCharFromFIO(dataProps) }}
                </span>
            </div>
            <ButtonItem
                v-if="dataAvatarProps?.name && typeMode === 'write'"
                label="Удалить изображение"
                icon="pi pi-trash"
                @click="deleteImage(dataProps)"
                class="p-button-danger"
            />
        </div>

        <div class="u-load-avatar" v-if="typeMode === 'write'">
            <CustomFileUploader
                name="filesSpecification"
                :multiple="false"
                :fileLimit="1"
                @select="selectFile"
                @remove="removeFile"
                @clear="clearFile"
                accept=".jpeg, .jpg, .png"
                invalidFileTypeMessage="Недопустимый тип файла, допустимые типы файлов: .jpeg, .jpg, .png."
            />
        </div>
    </div>
</template>

<script>
import helper from "@/utils/helper";
import CustomFileUploader from "@/components/CustomFileUploader/CustomFileUploader.vue";

export default {
    name: "AvatarItem",
    components: { CustomFileUploader },
    emits: ["deletePhoto", "selectFile", "removeFile", "clearFile"],
    props: {
        typeMode: {
            type: String,
            default: "read",
        },
        dataAvatarProps: {
            type: Object || Array,
            default: null,
        },
        dataProps: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getFirstCharFromFIO(dataProps) {
            if (dataProps) {
                if (dataProps.name && dataProps.surname) {
                    return `${dataProps.name[0]} ${dataProps.surname[0]}`;
                } else if (dataProps.name) {
                    return `${dataProps.name[0]}`;
                } else if (dataProps.surname) {
                    return `${dataProps.surname[0]}`;
                } else {
                    return "";
                }
            }
        },
        getImagePath(path) {
            return helper.getImagePath(path);
        },
        deleteImage(dataProps) {
            this.$emit("deletePhoto", dataProps);
        },
        selectFile(event) {
            this.$emit("selectFile", event);
        },
        removeFile(event) {
            this.$emit("removeFile", event);
        },
        clearFile(event) {
            this.$emit("clearFile", event);
        },
        getStyle() {
            if (this.typeMode === "write") {
                return "margin-bottom: 1rem";
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped></style>
