import handlerError from "@/utils/handlerError";

export default {
    namespaced: true,
    state: {
        errorLog: [],
        messageView: null,
        criticalError: false,
    },
    getters: {
        getErrorLog: (state) => state.errorLog,
        getMessageView: (state) => state.messageView,
        getCriticalError: (state) => state.criticalError,
    },
    mutations: {
        SET_CRITICAL_ERROR(state, payload) {
            state.criticalError = payload;
        },
        SET_LOG(state, payload) {
            let { message, type = "success" } = payload;

            if (type === "error") {
                message = handlerError.getStackTraceError(message);
            }
            if (state.errorLog.length >= 2000) {
                state.errorLog.shift();
            }
            const datetime = new Date();
            state.errorLog.push({ datetime, message, type });
            /*api отправляет ошибку на сервер*/
        },
        SET_MESSAGE_VIEW(state, payload) {
            switch (payload.type) {
                case "success": {
                    payload.summary = "Сообщение об успехе";
                    break;
                }
                case "warning": {
                    payload.summary = "Предупреждающее сообщение";
                    break;
                }
                case "error": {
                    payload.summary = "Сообщение об ошибке";
                    break;
                }
            }

            state.messageView = payload;
        },
        CLEAR_MESSAGE_VIEW(state) {
            state.messageView = null;
        },
    },
    actions: {},
};
