import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { organizationRoutes } from "@/modules/Organization";
import { inviteRoutes } from "@/modules/Invite";
import { employeesRoutes } from "@/modules/Employees";
import { templatesRoutes } from "@/modules/Template";
import { invitesRoutes } from "@//modules/Invites";

// editAccess - в зависимости от родительского маршрута, у пользователя может быть доступ к редактированию данных
// добавлено так как компонент используется для двух маршрутов, один для вывода своей компании, второй для вывода чужой компании

const routes = [
    ...organizationRoutes,
    ...inviteRoutes,
    ...employeesRoutes,
    ...templatesRoutes,
    ...invitesRoutes,
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/LoginView.vue"),
        meta: {
            layout: "Auth",
        },
    },
    {
        path: "/recovery_password",
        name: "PasswordRecovery",
        component: () => import("../views/PasswordRecoveryView.vue"),
        meta: {
            requiresAuth: false,
            layout: "Auth",
        },
    },
    {
        path: "/reset-password/:token",
        name: "PasswordReset",
        component: () => import("../views/PasswordResetView.vue"),
        meta: {
            requiresAuth: false,
            layout: "Auth",
        },
    },
    {
        path: "/profile",
        name: "MyProfile",
        component: () => import("../views/ProfileView.vue"),
        meta: {
            isRoot: true,
            requiresAuth: true,
            layout: "Default",
        },
    },
    {
        path: "/projects",
        name: "AllProjects",
        component: () => import(/* webpackChunkName: "about" */ "../views/AllProjectsView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
    },
    {
        path: "/projects/:group",
        name: "ProjectGroup",
        component: () => import(/* webpackChunkName: "about" */ "../views/ProjectsView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
        props(route) {
            const props = { ...route.params };
            props.group = +props.group;
            return props;
        },
    },
    {
        path: "/project",
        name: "ProjectItemWRAPPER",
        component: () => import("../views/ProjectTabMenuView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
        children: [
            {
                path: ":group/:project_id",
                name: "ProjectItem",
                component: () => import("../views/ProjectInfoView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.group = +props.group;
                    return props;
                },
            },
            {
                path: ":group/:project_id/project_settings",
                name: "ProjectSettings",
                component: () => import("../views/ProjectSettings"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Первоначальная настройка",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/members_project",
                name: "ProjectMembersView",
                component: () => import("../views/ProjectMembersView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Участники проекта",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/members_work",
                name: "WorkMembersView",
                component: () => import("../views/WorkMembersView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Участники работ",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                //TODO чекнуть
                path: ":group/:project_id/organization/:organization_id",
                name: "ProjectItemOrganization",
                component: () => import("../views/ProjectOrganizationView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: false,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Участники проекта",
                                to: { name: "ProjectMembersView" },
                            },
                            {
                                label: "Компания",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.organization_id = +props.organization_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/employee/:employee_id",
                name: "ProjectEmployeeProfile",
                component: () => import("../views/ProfileView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: false,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Участники проекта",
                                to: { name: "ProjectMembersView" },
                            },
                            {
                                label: "Сотрудник",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.organization_id = +props.organization_id;
                    props.employee_id = +props.employee_id;
                    return props;
                },
            },
            {
                //TODO чекнуть
                path: ":group/:project_id/organization/:organization_id/employee/:employee_id",
                name: "ProjectItemOrganizationEmployeeProfile",
                component: () => import("../views/ProfileView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: false,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Участники проекта",
                                to: { name: "ProjectMembersView" },
                            },
                            {
                                label: "Компания",
                                to: { name: "ProjectItemOrganization" },
                            },
                            {
                                label: "Сотрудник",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.organization_id = +props.organization_id;
                    props.employee_id = +props.employee_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/permissive_documentation",
                name: "ProjectPermissiveDocumentation",
                component: () => import("../views/ProjectPermissiveDocumentation.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Разрешительная документация",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/building_control",
                name: "ProjectBuildingControl",
                component: () => import("../views/ProjectBuildingControl.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Строительный контроль",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/control/work/:work_id/:work_list_id",
                name: "ProjectControl",
                component: () => import("../views/ProjectBuildingControlWorkScope.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Строительный контроль",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.work_list_id = +props.work_list_id;
                    props.work_id = +props.work_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/entrance_control",
                name: "ProjectEntranceControl",
                component: () => import("../views/ProjectEntranceControl.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Входной контроль",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/working_documentation",
                name: "ProjectWorkingDocumentation",
                component: () => import("../views/ProjectWorkingDocumentation.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Рабочая документация",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/object_documentation",
                name: "ProjectObjectDocumentation",
                component: () => import("../views/ProjectObjectDocumentation.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Документация по объекту",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/executive_documentation",
                name: "ProjectExecutiveDocumentationView",
                component: () => import("../views/ProjectExecutiveDocumentationView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            // {
            //     path: ":project_id/executive_documentation/work/:work_name/:work_id",
            //     name: "ProjectWorkView",
            //     component: () => import("../views/ProjectWorkView.vue"),
            //     meta: {
            //         requiresAuth: true,
            //         editAccess: true,
            //         breadCrumb(route) {
            //             const work_name = decodeURIComponent(
            //                 escape(window.atob(route.params.work_name))
            //             );
            //             return [
            //                 {
            //                     label: "Проекты",
            //                     to: { name: "Projects" },
            //                 },
            //                 {
            //                     label: `Проект`,
            //                     to: { name: "ProjectItem" },
            //                 },
            //                 {
            //                     label: `Исполнительная документация`,
            //                     to: {
            //                         name: "ProjectExecutiveDocumentationView",
            //                     },
            //                 },
            //                 {
            //                     label: `${work_name}`,
            //                 },
            //             ];
            //         },
            //     },
            //     props(route) {
            //         const props = { ...route.params };
            //         props.project_id = +props.project_id;
            //         props.work_id = +props.work_id;
            //         return props;
            //     },
            // },
            {
                path: ":group/:project_id/executive_documentation/work/:work_id/:work_list_id",
                name: "ProjectScopeView",
                component: () => import("../views/ProjectScopeView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: "Объемы работ",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    props.work_list_id = +props.work_list_id;
                    props.work_id = +props.work_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/executive_documentation/document/:document_name/:document_id",
                name: "view_document",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/ProjectDocumentSections.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${document_name}`,
                            },
                        ];
                    },
                },
            },
            {
                path: ":group/:project_id/executive_documentation/document/:document_name/:document_id/section/:section_name/:section_id",
                name: "edit_journal",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/EditDocumentation.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "view_document",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                            },
                        ];
                    },
                },
            },
            {
                path: ":group/:project_id/executive_documentation/document/:document_name/:document_id/section/:section_name/:section_id/edit_row/:row_id",
                name: "edit_journal_row",
                component: () => import(/* webpackChunkName: "about" */ "../views/documentEdit"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "view_document",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                                to: {
                                    name: "edit_journal",
                                },
                            },
                            {
                                label: "Редактирование строки",
                            },
                        ];
                    },
                },
            },
            {
                path: ":group/:project_id/executive_documentation/document/:document_name/:document_id/section/:section_name/:section_id/create_row",
                name: "create_journal_row",
                component: () => import(/* webpackChunkName: "about" */ "../views/documentEdit"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "view_document",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                                to: {
                                    name: "edit_journal",
                                },
                            },
                            {
                                label: "Добавление строки",
                            },
                        ];
                    },
                },
            },
            {
                path: ":group/:project_id/executive_documentation/work/:work_name/:work_id/document/:document_name/:document_id/section/:section_name/:section_id",
                name: "work_documentation_view",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/EditDocumentation.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        const work_name = decodeURIComponent(
                            escape(window.atob(route.params.work_name))
                        );
                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${work_name}`,
                                to: {
                                    name: "ProjectWorkView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "work_sections_documentation",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                            },
                        ];
                    },
                },
                props: true,
            },
            {
                path: ":group/:project_id/executive_documentation/work/:work_name/:work_id/document/:document_name/:document_id/section/:section_name/:section_id/create_row",
                name: "work_documentation_create",
                component: () => import(/* webpackChunkName: "about" */ "../views/documentEdit"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");
                        const work_name = decodeURIComponent(
                            escape(window.atob(route.params.work_name))
                        );
                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${work_name}`,
                                to: {
                                    name: "ProjectWorkView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "work_sections_documentation",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                                to: {
                                    name: "work_documentation_view",
                                },
                            },
                            {
                                label: "Добавление строки",
                            },
                        ];
                    },
                },
                props: true,
            },
            {
                path: ":group/:project_id/executive_documentation/work/:work_name/:work_id/document/:document_name/:document_id/section/:section_name/:section_id/edit_row/:row_id",
                name: "work_documentation_edit",
                component: () => import(/* webpackChunkName: "about" */ "../views/documentEdit"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");
                        const work_name = decodeURIComponent(
                            escape(window.atob(route.params.work_name))
                        );
                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        let section_num = route.params.section_id.slice(1);
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${work_name}`,
                                to: {
                                    name: "ProjectWorkView",
                                },
                            },
                            {
                                label: `${document_name}`,
                                to: {
                                    name: "work_sections_documentation",
                                },
                            },
                            {
                                label: `Раздел ${section_num}`,
                                to: {
                                    name: "work_documentation_view",
                                },
                            },
                            {
                                label: "Редактирование строки",
                            },
                        ];
                    },
                },
                props: true,
            },
            {
                path: ":group/:project_id/executive_documentation/work/:work_name/:work_id/document/:document_name/:document_id/",
                name: "work_sections_documentation",
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/ProjectDocumentSections.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");
                        const work_name = decodeURIComponent(
                            escape(window.atob(route.params.work_name))
                        );
                        let document_name = decodeURIComponent(
                            escape(window.atob(route.params.document_name))
                        );
                        let name = document_name.split("");
                        name[0] = name[0].toUpperCase();
                        document_name = name.join("");
                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Исполнительная документация",
                                to: {
                                    name: "ProjectExecutiveDocumentationView",
                                },
                            },
                            {
                                label: `${work_name}`,
                                to: {
                                    name: "ProjectWorkView",
                                },
                            },
                            {
                                label: `${document_name}`,
                            },
                        ];
                    },
                },
                props: true,
            },
            {
                path: ":group/:project_id/specification",
                name: "ProjectSpecificationView",
                component: () => import("../views/ProjectSpecificationView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Спецификация",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/material_accounting",
                name: "ProjectMaterialAccountingView",
                component: () => import("../views/ProjectMaterialAccountingView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "Учет материалов",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
            {
                path: ":group/:project_id/production_schedule",
                name: "ProjectProductionScheduleView",
                component: () => import("../views/ProjectProductionScheduleView.vue"),
                meta: {
                    requiresAuth: true,
                    editAccess: true,
                    breadCrumb(route) {
                        let projectGroup;
                        route.params.group == 0
                            ? (projectGroup = "Несгруппированные проекты")
                            : (projectGroup = "Объект");

                        return [
                            {
                                label: "Проекты",
                                to: { name: "AllProjects" },
                            },
                            {
                                label: `${projectGroup}`,
                                to: {
                                    name: "ProjectGroup",
                                },
                            },
                            {
                                label: "Проект",
                                to: { name: "ProjectItem" },
                            },
                            {
                                label: "График производства работ",
                            },
                        ];
                    },
                },
                props(route) {
                    const props = { ...route.params };
                    props.project_id = +props.project_id;
                    return props;
                },
            },
        ],
        props: true,
    },
    {
        path: "/licenses",
        name: "Licenses",
        component: () => import("../views/LicensesView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
    },
    {
        path: "/test",
        name: "tests",
        component: () => import(/* webpackChunkName: "about" */ "../views/GanttView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
    },
    {
        path: "/employee/:employee_id",
        name: "ProfileEmployeeMyOrganization",
        component: () => import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
        meta: {
            requiresAuth: true,
            editAccess: true,
            layout: "Default",
            breadCrumb() {
                return [
                    {
                        label: "Мои сотрудники",
                        to: { name: "MyEmployees" },
                    },
                    {
                        label: "Сотрудник",
                    },
                ];
            },
        },
        props(route) {
            const props = { ...route.params };
            props.employee_id = +props.employee_id;
            return props;
        },
    },
    {
        path: "/",
        redirect: "/profile",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (to.matched[0].path !== "/project") {
            if (store.getters["role/userRole"]) {
                store.dispatch("role/RESET_ROLE");
            }
            if (store.getters["right/userRight"]) {
                store.dispatch("right/RESET_RIGHT_PROJECT");
            }
        }
        if (store.getters["user/isAuthenticated"] && store.getters["user/stateUser"]) {
            next();
        } else if (store.getters["user/isAuthenticated"] && !store.getters["user/stateUser"]) {
            await store.dispatch("user/WHOAMI");
            if (store.getters["user/isAuthenticated"] && store.getters["user/stateUser"]) {
                next();
            } else {
                next("/login");
            }
        } else {
            next("/login");
        }
    } else {
        next();
    }
});

export default router;
