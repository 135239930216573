<template>
    <u-header
        v-if="isAuth && user"
        @openSidebar="isOpen = !isOpen"
        @closeSidebar="isOpen = false"
        :isOpen="isOpen"
    />
    <main>
        <div class="main__content">
            <u-sidebar v-if="isAuth && user" :isOpen="isOpen" @openSidebar="isOpen = !isOpen" />
            <div
                class="view__content view__content-full"
                :class="{
                    'view__content-auth': !isAuth,
                }"
            >
                <div class="breadcrumb-wrapper" v-if="breadCrumbs.length">
                    <BreadcrumbItem :model="breadCrumbs" />
                </div>
                <router-view :isOpen="isOpen" />
            </div>
        </div>
    </main>
    <u-footer />
</template>

<script>
import UHeader from "@/components/Header/index.vue";
import UFooter from "@/components/Footer/index.vue";
import USidebar from "@/components/Sidebar/index.vue";
import { mapGetters } from "vuex";

export default {
    name: "DefaultLayout",
    components: { USidebar, UFooter, UHeader },
    data() {
        return {
            isOpen: true,
        };
    },
    created() {
        if (window.innerWidth < 769) this.isOpen = false;
    },
    computed: {
        ...mapGetters({
            isAuth: "user/isAuthenticated",
            user: "user/stateUser",
        }),
        breadCrumbs() {
            if (this.$route.meta?.breadCrumb) {
                if (typeof this.$route.meta.breadCrumb === "function") {
                    return this.$route.meta.breadCrumb.call(this, this.$route);
                }
                return this.$route.meta.breadCrumb;
            } else {
                return [];
            }
        },
    },
};
</script>

<style scoped></style>
