<template>
    <DialogItem
        class="modal__width"
        :header="`Карточка организации`"
        v-model:visible="displayModal"
        :modal="true"
        :closable="false"
    >
        <CardOrganizationForm
            :submit-label="`Сохранить`"
            :data-organization-props="props.dataOrganizationProps"
            @nextPage="submitForm"
            @handlerDeletePhoto="$emit(`handlerDeletePhoto`, $event)"
        >
            <template #button>
                <ButtonItem
                    label="Отмена"
                    icon="pi pi-times"
                    @click="closeModal"
                    class="p-button-text"
                />
            </template>
        </CardOrganizationForm>
    </DialogItem>
</template>

<script setup>
import { onMounted, ref } from "vue";
import CardOrganizationForm from "@/modules/Organization/components/CardOrganizationForm/CardOrganizationForm.vue";

const emit = defineEmits(["handlerDeletePhoto", "close", "submitHandler"]);
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
    dataOrganizationProps: {
        type: Object,
        required: true,
    },
});

const displayModal = ref(false);

onMounted(() => {
    displayModal.value = props.isActive;
});

/**
 * Закрытие модального окна
 */
const closeModal = () => emit("close");

/**
 * Передача события подтверждения формы родителю
 */
const submitForm = async (form) => {
    emit("submitHandler", form.formData);
    emit("close");
};
</script>
