import { onBeforeUnmount, onMounted, ref } from "vue";

export const useIsMobile = () => {
    const pages = ref(5);
    const screenSize = ref("desktop");

    const checkMobileWidth = () => {
        if (window.innerWidth <= 576 && window.innerWidth >= 321) {
            pages.value = 3;
            screenSize.value = "mobile";
        } else if (window.innerWidth <= 320) {
            pages.value = 2;
        } else {
            pages.value = 5;
            screenSize.value = "desktop";
        }
    };

    onMounted(() => {
        if (window.innerWidth < 577) screenSize.value = "mobile";
        window.addEventListener("resize", checkMobileWidth);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("resize", checkMobileWidth);
    });

    return { pages, screenSize };
};
