import { sendGetDownload } from "@/api";

async function getFileApi({ file_id, filename }, ftype) {
    try {
        return await sendGetDownload(`files/get_file_by_id?file_id=${file_id}`, filename, ftype);
    } catch (error) {
        console.log("ERROR!!! - getFileApi", error);
        throw error;
    }
}

export { getFileApi };
