import { helpers, maxLength, minLength, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export const useValidation = (inn) => {
    const rules = {
        inn: {
            numeric: helpers.withMessage("В поле 'ИНН' могут быть только цифры", numeric),
            minLength: helpers.withMessage(
                "Поле 'ИНН' не должно быть меньше 10 символов",
                minLength(10)
            ),
            maxLength: helpers.withMessage(
                "Поле 'ИНН' не должно превышать 10 символов",
                maxLength(10)
            ),
        },
    };

    const state = {
        inn,
    };

    const v$ = useVuelidate(rules, state);

    return { v$ };
};
