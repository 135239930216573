<template>
    <aside :class="{ small: !isOpen, large: isOpen }">
        <div class="sidebar__logo logo">
            <router-link to="/">
                <img alt="Locus logo" src="@/assets/logo.png" />
            </router-link>
        </div>
        <nav>
            <PanelMenu :model="navigation">
                <template #item="{ item }">
                    <router-link
                        :to="item.to"
                        custom
                        v-if="item.role === 'all' || (item.role === 'manager' && user.manager)"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                        <a
                            :href="href"
                            @click.prevent="onNavigate(navigate, item.label)"
                            class="nav__item"
                            :class="{
                                'active-link': isActive,
                                'active-link-exact': isExactActive,
                            }"
                        >
                            <i :class="item.icon" />
                            <span class="nav__name">
                                {{ item.label }}
                                <BadgeItem
                                    v-if="item.label === 'Приглашения' && invitesValue > 0"
                                    class="sidebar__notification-badge"
                                    :value="invitesValue"
                                    severity="warning"
                                />
                            </span>
                        </a>
                    </router-link>
                </template>
            </PanelMenu>
        </nav>
    </aside>
</template>

<script>
import { navigation } from "@/data/naviagation";
import { mapGetters } from "vuex";
import { getInvitesForOrganizationAPI } from "@/modules/Invites/api/getInvitesForOrganizationAPI";

export default {
    emits: ["openSidebar"],
    name: "u-sidebar",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            navigation: [],
            invitesValue: 0,
        };
    },
    created() {
        this.navigation = navigation;
        this.getInviteNotificationCount();
    },
    computed: {
        ...mapGetters({ user: "user/stateUser" }),
    },
    methods: {
        onNavigate(navigate, navigateTo) {
            if (navigateTo === "Приглашения") this.invitesValue = 0;
            navigate();
            if (window.innerWidth < 769) this.$emit("openSidebar");
        },
        async getInviteNotificationCount() {
            const invites = await getInvitesForOrganizationAPI(this.user.organization);
            this.invitesValue = invites.length;
        },
    },
};
</script>

<style lang="scss">
aside {
    background: -webkit-linear-gradient(180deg, rgb(15, 26, 242), rgb(27, 55, 140));
    background: -moz-linear-gradient(180deg, rgb(15, 26, 242), rgb(27, 55, 140));
    background: linear-gradient(180deg, rgb(15, 26, 242), rgb(27, 55, 140));
    transition: 0.2s;
    width: 240px;
    position: fixed;
    top: 20px;
    bottom: 20px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 1;
    .sidebar__logo {
        width: 100%;
        height: 170px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
        img {
            max-width: 100%;
            height: 100%;
        }
    }
    header img {
        width: 100%;
        height: 100%;
    }

    .p-panelmenu .p-panelmenu-panel {
        box-shadow: none;
    }
    nav {
        overflow: hidden;
        text-align: left;
        .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
            background: transparent;
            & > a {
                color: #ffffff;
                display: block;
                white-space: nowrap;
                padding: 16px 20px;
                border: 0;
            }
            & > a:focus,
            & > a:focus-visible,
            & > a:focus-within {
                outline: 0 none !important;
                outline-offset: 0 !important;
                box-shadow: none !important;
            }
        }
        .nav__item.active-link {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                margin-top: -15px;
                right: 0;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-right: 30px solid #ffffff;
                border-bottom: 15px solid transparent;
            }
        }
        .nav__name {
            padding-left: 20px;
            position: relative;
        }
        .sidebar__notification-badge {
            position: absolute;
            top: -14px;
            right: -16px;
            z-index: -1;
        }
    }
}
</style>
