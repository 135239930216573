import { sendGet } from "@/api";

export async function getOrganizationType() {
    try {
        return await sendGet("organization/type");
    } catch (error) {
        console.log("ERROR!!! - getOrganizationType", error);
        throw error;
    }
}
