export const dataEmployees = [
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Иван Иванович",
        position: "электрик",
        numberNRC: "64564524234",
        role: [
            {
                name: "Исполнитель",
                id: 1,
            },
        ],
        disabled: false,
        id: 1,
        responsibleEmployee: "Свайные работы",
    },
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Петр Иванович",
        position: "инженер",
        numberNRC: "1231234124",
        role: [
            {
                name: "Руководитель",
                id: 2,
            },
        ],
        disabled: true,
        id: 2,
        responsibleEmployee: "Свайные работы",
    },
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Василий Иванович",
        position: "директор",
        numberNRC: "12351365234",
        role: [
            {
                name: "Руководитель",
                id: 2,
            },
            {
                name: "Исполнитель",
                id: 2,
            },
        ],
        disabled: true,
        id: 3,
        responsibleEmployee: "Свайные работы",
    },
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Евгений Иванович",
        position: "электрик",
        numberNRC: "443435123412",
        role: [
            {
                name: "Управляющий",
                id: 2,
            },
        ],
        disabled: false,
        id: 4,
        responsibleEmployee: "Свайные работы",
    },
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Федор Иванович",
        position: "электрик",
        numberNRC: "64564524234",
        role: [
            {
                name: "Управляющий",
                id: 2,
            },
        ],
        disabled: false,
        id: 5,
        responsibleEmployee: "Свайные работы",
    },
    {
        lastname: "Иванов",
        firstname: "Иван",
        middlename: "Иванович",
        fio: "Иванов Павел Иванович",
        position: "электрик",
        numberNRC: "64564524234",
        role: [
            {
                name: "Управляющий",
                id: 2,
            },
        ],
        disabled: false,
        id: 6,
        responsibleEmployee: "Свайные работы",
    },
];
