import { createApp, h } from "vue";
import store from "@/store";
import router from "@/router";
import LoginModal from "./modal/index";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Password from "primevue/password";

export const promiseModal = () => {
    return new Promise((resolve) => {
        const dialog = createApp({
            methods: {
                closeHandler(fn, arg) {
                    fn(arg);
                    dialog.$el.remove();
                },
            },
            render() {
                return h(LoginModal, {
                    onClick: (response) => {
                        this.closeHandler(resolve, response);
                    },
                });
            },
        })
            .use(PrimeVue)
            .component("ButtonItem", Button)
            .component("CardItem", Card)
            .component("InputText", InputText)
            .component("PasswordItem", Password)
            .use(store)
            .use(router)
            .mount("#modalReAuth");
    });
};
