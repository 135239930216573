import store from "@/store";
import { sendGet, sendPost, sendPut } from "@/api";

async function blockOrganizationApi(id, organization) {
    try {
        const response = await sendPut(`organization/block?id_=${id}`);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: `Организация ${organization.name} заблокирована`,
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - blockOrganizationApi", error);
        throw error;
    }
}

async function unblockOrganizationApi(id, organization) {
    try {
        const response = await sendPut(`organization/unblock?id_=${id}`);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: `Организация ${organization.name} разблокирована`,
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - unblockOrganizationApi", error);
        throw error;
    }
}

async function getOrganizationsProjectDataApi(project_id, org_id) {
    try {
        return await sendGet(`organization/all?project=${project_id}&parent=${org_id}`);
    } catch (error) {
        console.log("ERROR!!! - getOrganizationsProjectDataApi", error);
        throw error;
    }
}

async function getInvitesOrganizationsProjectDataApi(data) {
    try {
        const { content } = await sendGet("invite/invites_from_sender_organization", data);
        return content;
    } catch (error) {
        console.log("ERROR!!! - getInvitesOrganizationsProjectDataApi", error);
        throw error;
    }
}

async function inviteExistOrganizationAPI(data) {
    try {
        const response = await sendPost("invite/exist_organization", data);
        return response;
    } catch (error) {
        console.log("ERROR!!! - inviteExistOrganizationAPI", error);
        throw error;
    }
}

async function getOrganizationAllApi() {
    try {
        const { content } = await sendGet("organization/all?project=0&parent=0");
        return content;
    } catch (error) {
        console.log("ERROR!!! - getOrganizationAllApi", error);
        throw error;
    }
}

async function getOrganizationInfoByInn(inn, loading = {}) {
    loading.value = true;
    try {
        const { content } = await sendGet(`invite/parsed_data?inn=${inn}`);
        return content;
    } catch (error) {
        console.log("ERROR!!! - getOrganizationInfoByInn", error);
        throw error;
    } finally {
        loading.value = false;
    }
}

export {
    blockOrganizationApi,
    unblockOrganizationApi,
    getOrganizationsProjectDataApi,
    getInvitesOrganizationsProjectDataApi,
    getOrganizationAllApi,
    inviteExistOrganizationAPI,
    getOrganizationInfoByInn,
};
