<script setup>
import LoaderItem from "@/components/Loader/index.vue";
import { useIsMobile } from "@/hooks/useIsMobile";
import { sendDecisionForInviteAPI } from "@/modules/Invites/api/sendDecisionForInvite";
import { getInvitesForOrganizationAPI } from "@/modules/Invites/api/getInvitesForOrganizationAPI";
import { onMounted, ref } from "vue";
import { mapMutations, mapGetters } from "@/store/wrapper_store";
import { useConfirm } from "primevue/useconfirm";

const { stateUser } = mapGetters("user");
const { stateLicense } = mapGetters("user");

const emit = defineEmits(["error", "reload"]);

const confirm = useConfirm();
const { SET_MESSAGE_VIEW } = mapMutations("general");

const showTemplate = (invite_id, status_id) => {
    confirm.require({
        group: "templating",
        header: "Подтверждение",
        message: "Вы уверены в принятом решении?",
        icon: "pi pi-question-circle",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        accept: () => {
            setDecision(invite_id, status_id);
        },
        reject: () => {
            confirm.close();
        },
    });
};

const setDecision = async (invite_id, status_id) => {
    if (status_id !== 2 && checkLicense()) return;

    try {
        await sendDecisionForInviteAPI({ invite_id, status_id, decision_date: new Date() });
    } catch (error) {
        emit("error", error);
    } finally {
        emit("reload");
    }
};

const invites_list = ref([]);
const { pages } = useIsMobile();

const loading = ref(true);

onMounted(async () => {
    try {
        invites_list.value = await getInvitesForOrganizationAPI(stateUser.value.organization);
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
});

const checkLicense = () => {
    if (
        !stateLicense.value.length ||
        !stateLicense.value.find(
            (license) => new Date(license.expiry_date).getTime() > new Date().getTime()
        )?.id
    ) {
        SET_MESSAGE_VIEW({
            message:
                "Проверьте актуальность лицензионных ключей. Убедитесь что срок действия существующих ключей не истек.",
            type: "warn",
        });
        return true;
    } else {
        return false;
    }
};
</script>

<template>
    <div>
        <LoaderItem v-if="loading" />
        <div v-else class="u-block-table">
            <DataTableItem
                :value="invites_list"
                :paginator="true"
                :rows="10"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50]"
                currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}"
                responsiveLayout="scroll"
                paginatorPosition="top"
                :pageLinkSize="pages"
                class="u-table"
            >
                <ColumnItem
                    header="Наименование проекта"
                    field="project.full_name"
                    :sortable="true"
                >
                    <template #body="{ data }">
                        {{ data.project.full_name }}
                    </template>
                </ColumnItem>
                <ColumnItem header="Наименование компании" field="sender.name" :sortable="true">
                    <template #body="{ data }">
                        {{ data.sender.name }}
                    </template>
                </ColumnItem>
                <ColumnItem header="ИНН" field="sender.tin" :sortable="true">
                    <template #body="{ data }">
                        {{ data.sender.tin }}
                    </template>
                </ColumnItem>
                <ColumnItem header="Роль" field="role.name" :sortable="true">
                    <template #body="{ data }">
                        {{ data.role.name }}
                    </template>
                </ColumnItem>
                <ColumnItem>
                    <template #body="{ data }">
                        <div class="button__actions button__group">
                            <ButtonItem
                                label="Принять приглашение"
                                @click="showTemplate(data.id, 1)"
                            />
                            <ButtonItem label="Отказать" @click="showTemplate(data.id, 2)" />
                        </div>
                    </template>
                </ColumnItem>
                <template #empty>
                    <b>Нет активных приглашений</b>
                </template>
            </DataTableItem>
        </div>
    </div>
    <ConfirmDialog group="templating">
        <template #message="slotProps">
            <div class="flex p-4">
                <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
        </template>
    </ConfirmDialog>
</template>

<style scoped>
.button__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>
