import { sendPut } from "@/api";
import store from "@/store";

export async function blockEmployeeApi(id, data) {
    try {
        const response = await sendPut(`worker/block?id_=${id}`);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: `Сотрудник ${data.surname} ${data.name} ${data.patronymic} заблокирован`,
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - blockEmployeeApi", error);
        throw error;
    }
}

export async function unblockEmployeeApi(id, data) {
    try {
        const response = await sendPut(`worker/unblock?id_=${id}`);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: `Сотрудник ${data.surname} ${data.name} ${data.patronymic} разблокирован`,
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - blockEmployeeApi", error);
        throw error;
    }
}
