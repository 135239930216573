<template>
    <DialogItem
        class="modal__width"
        :header="`Загрузка файла`"
        v-model:visible="displayModal"
        :modal="true"
        :closable="false"
    >
        <LoaderItem v-if="loading" />
        <form v-else @submit.prevent="submitForm">
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="name"
                        type="text"
                        v-model="v$.fileData.name.$model"
                        :class="{
                            'p-invalid': v$.fileData.name.$invalid && submitted,
                        }"
                    />
                    <label for="name"><sup>*</sup>Наименование документа</label>
                </span>
                <div v-if="v$.fileData.name.$invalid && submitted">
                    <small v-for="error in v$.fileData.name.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="button__upload__group">
                <CustomFileUploader
                    name="filesSpecification"
                    @select="selectFile"
                    @remove="removeFile"
                    @clear="clearFile"
                    accept=".odt,.ods,.xls,.xlsx,.docx,.doc,.docs,.pdf,.jpg,.png"
                    invalidFileTypeMessage="Некорректный формат файла"
                    :multiple="false"
                    :fileLimit="1"
                    :is-file-uploaded="isFileUploaded"
                />
            </div>
            <div class="modal__footer">
                <ButtonItem
                    label="Отмена"
                    icon="pi pi-times"
                    @click="closeModal"
                    class="p-button-text"
                />
                <ButtonItem label="Сохранить" icon="pi pi-check" type="submit" />
            </div>
        </form>
    </DialogItem>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref, toRefs } from "vue";
import { useValidation } from "@/modules/ListDocuments/components/FileUploadDocsModal/hooks/useValidation";
import LoaderItem from "@/components/Loader/index.vue";
import CustomFileUploader from "@/components/CustomFileUploader/CustomFileUploader.vue";

const emit = defineEmits(["close", "submitHandler", "error"]);
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
    profileData: {
        type: Object,
        default: null,
    },
    organizationData: {
        type: Object,
        default: null,
    },
});

const route = useRoute();

const { isActive, profileData, organizationData } = toRefs(props);
const fileData = ref({
    name: null,
    file: null,
    organization: null,
});
const isFormCorrect = ref(null);
const isFileUploaded = ref(""); //See to refactor
const submitted = ref(false);
const displayModal = ref(false);
const loading = ref(true);

const { v$ } = useValidation(fileData);

onMounted(async () => {
    try {
        displayModal.value = isActive.value;
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
});

const removeFile = (event) => {
    fileData.value.file = event.files;
};

const clearFile = () => {
    fileData.value.file = [];
};

const selectFile = (event) => {
    isFileUploaded.value = "";
    fileData.value.file = event.files[0];
};

const closeModal = () => {
    submitted.value = false;
    emit("close");
};

const submitForm = async () => {
    try {
        submitted.value = true;
        isFormCorrect.value = await v$.value.$validate();

        if (isFormCorrect.value && fileData.value.file) {
            const formData = createFormData(fileData.value);
            emit("submitHandler", formData);
            emit("close");
        } else {
            isFileUploaded.value = "error";
        }
    } catch (e) {
        emit("error", e);
        emit("close");
    }
};

const createFormData = (fileData) => {
    const formData = new FormData();
    const fileInfo = {
        filename: fileData.name,
    };

    if (route.name === "MyOrganization") {
        formData.append("file", fileData.file);
    } else {
        formData.append("files", fileData.file);
    }

    if (organizationData.value) {
        fileInfo.organization_id = organizationData.value.id;
        formData.append("info", JSON.stringify(fileInfo));
    } else if (profileData.value) {
        fileInfo.worker_id = profileData.value.id;
        formData.append("worker", JSON.stringify(fileInfo));
    }

    return formData;
};
</script>
