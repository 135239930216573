<template>
    <div class="wrapper__form-page">
        <form @submit.prevent="submitForm">
            <div class="modal__input-block">
                <span class="p-float-label">
                    <AvatarItem
                        :data-props="employee"
                        :type-mode="'write'"
                        @selectFile="handlerSelectFile"
                        @removeFile="handlerRemoveFile"
                        @clearFile="handlerClearFile"
                        :key="employee?.WorkerPhoto?.name"
                    />
                </span>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="post"
                        type="text"
                        v-maska:[optionsMask.spaceMask()]
                        v-model="v$.employee.post.$model"
                        :class="{
                            'p-invalid': v$.employee.post.$invalid && submitted,
                        }"
                    />
                    <label for="post"> <sup>*</sup>Должность</label>
                </span>
                <div v-if="v$.employee.post.$invalid && submitted">
                    <small v-for="error in v$.employee.post.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="surname"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.employee.surname.$model"
                        :class="{
                            'p-invalid': v$.employee.surname.$invalid && submitted,
                        }"
                    />
                    <label for="surname"> <sup>*</sup>Фамилия</label>
                </span>
                <div v-if="v$.employee.surname.$invalid && submitted">
                    <small v-for="error in v$.employee.surname.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="name"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.employee.name.$model"
                        :class="{
                            'p-invalid': v$.employee.name.$invalid && submitted,
                        }"
                    />
                    <label for="name"> <sup>*</sup>Имя</label>
                </span>
                <div v-if="v$.employee.name.$invalid && submitted">
                    <small v-for="error in v$.employee.name.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="patronymic"
                        type="text"
                        v-maska:[optionsMask.letters()]
                        v-model="v$.employee.patronymic.$model"
                        :class="{
                            'p-invalid': v$.employee.patronymic.$invalid && submitted,
                        }"
                    />
                    <label for="patronymic">Отчество</label>
                </span>
                <div v-if="v$.employee.patronymic.$invalid && submitted">
                    <small v-for="error in v$.employee.patronymic.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="email"
                        type="text"
                        v-model="v$.employee.email.$model"
                        :class="{
                            'p-invalid': v$.employee.email.$invalid && submitted,
                        }"
                    />
                    <label for="email"> <sup>*</sup>Email</label>
                </span>
                <div v-if="v$.employee.email.$invalid && submitted">
                    <small v-for="error in v$.employee.email.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <PasswordItem
                        id="password"
                        :feedback="false"
                        :autocomplete="false"
                        v-maska:[optionsMask.password()]
                        v-model="v$.password.$model"
                        :class="{
                            'p-invalid': v$.password.$invalid && submitted,
                        }"
                        toggleMask
                    />
                    <label for="password"> <sup>*</sup>Пароль</label>
                </span>
                <div v-if="v$.password.$invalid && submitted">
                    <small v-for="error in v$.password.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <PasswordItem
                        id="replayPassword"
                        :feedback="false"
                        v-maska:[optionsMask.password()]
                        v-model="v$.replayPassword.$model"
                        :class="{
                            'p-invalid': v$.replayPassword.$invalid && submitted,
                        }"
                        toggleMask
                    />
                    <label for="replayPassword"> <sup>*</sup>Подтверждение пароля</label>
                </span>
                <div v-if="v$.replayPassword.$invalid && submitted">
                    <small v-for="error in v$.replayPassword.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="number_nrs"
                        type="text"
                        v-model="v$.employee.number_nrs.$model"
                        :class="{
                            'p-invalid': v$.employee.number_nrs.$invalid && submitted,
                        }"
                    />
                    <label for="number_nrs"> <sup>*</sup>Идентификационный номер НРС</label>
                </span>
                <div v-if="v$.employee.number_nrs.$invalid && submitted">
                    <small v-for="error in v$.employee.number_nrs.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <DividerItem />
            <div class="modal__footer modal__footer_mobile">
                <ButtonItem v-if="props.formData" label="Назад" @click="prevPage" />
                <ButtonItem label="Зарегистрироваться" icon="pi pi-check" type="submit" />
            </div>
        </form>
    </div>
</template>

<!--Компонент используется в OrganizationInvitePage и в ProfileInvitePage-->
<script setup>
import { ref, onMounted } from "vue";
import { useValidation } from "@/modules/Invite/components/CreateEmployeeForm/hooks/useValidation";
import masks from "@/utils/masks";
import AvatarItem from "@/components/Avatar/index.vue";

// emits and props
const emit = defineEmits(["next-page"]);
const props = defineProps(["formData"]);

// data
const employee = ref({
    manager: false,
    post: "", // Должность
    name: "", // Имя
    surname: "", // Фамилия
    patronymic: null, // Отчество (Необязательно)
    email: "", // Почта
    number_nrs: "", // Номер НРС
});
const password = ref(""); // Пароль
const replayPassword = ref(null); // Повторение пароля
const fileData = ref({
    file: null,
});
const submitted = ref(false);
const isFormCorrect = ref(null);
const optionsMask = ref(masks);

// validation
const { v$ } = useValidation(employee, password, replayPassword);

onMounted(() => {
    getLocalStorageData();
});

/**
 * Генерация события подтверждения формы
 */
const submitForm = async () => {
    submitted.value = true;
    isFormCorrect.value = await v$.value.$validate();

    employee.value.password = password.value;
    setLocalStorageData();

    if (isFormCorrect.value) {
        const data = {
            data: {
                worker: employee.value,
                photo_worker: fileData.value,
            },
            pageIndex: 1,
        };
        emit("next-page", data);
    }
};

/**
 * Обработчик выбора файла, записывает объект файла в file
 * @param event - событие выбора файла
 */
const handlerSelectFile = (event) => {
    fileData.value.file = event.files[0];
};

const handlerRemoveFile = (event) => {
    fileData.value.file = event.files;
};

const handlerClearFile = () => {
    fileData.value.file = [];
};

/**
 * Событие обработки нажатия кнопки `Назад`
 */
const prevPage = () => {
    emit("prev-page", { pageIndex: 1 });
};

const getLocalStorageData = () => {
    const data = localStorage.getItem("userForm");
    if (data) {
        const userData = JSON.parse(data);
        employee.value = userData.data.worker;
        fileData.value = userData.data.photo_worker;
    }
};

const setLocalStorageData = () => {
    const json = JSON.stringify({
        data: {
            worker: employee.value,
            photo_worker: fileData.value,
        },
    });
    localStorage.setItem("userForm", json);
};
</script>
