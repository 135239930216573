<template>
    <header class="fixed__header view__header-small">
        <!-- для узкого навбара :class="{ 'fixed__header view__header-small': isOpen }" -->
        <!--         <div class="header__logo__mobile logo">
            <img alt="Vue logo" src="@/assets/logo.png" />
        </div>
        <ButtonItem class="header__button-nav" @click="$emit('openSidebar')">
            <i v-if="isOpen" class="pi pi-times" />
            <i v-else class="pi pi-bars" />
        </ButtonItem> -->
        <div class="header__buttons">
            <div class="info-role-right">
                <div class="info__item info-role__item">
                    <ChipItem v-if="userRole" :label="userRole.name" />
                </div>

                <div class="info__item info-right__item">
                    <ChipItem v-if="user" :label="user.post" />
                </div>
                <!-- <div class="info__item info-right__item" v-if="userRight">
                        <ChipItem
                            v-for="item in userRight"
                            :key="item.id"
                            :label="item.name"
                            />
                    </div> -->
            </div>

            <AvatarItem
                @click="openUserMenu"
                :data-avatar-props="user.WorkerPhoto"
                :data-props="user"
                :type-mode="'read'"
            />

            <OverlayPanelItem ref="overlayPanel" class="u-menu u-tool-menu">
                <div class="username">{{ user.surname }} {{ user.name }} {{ user.patronymic }}</div>
                <div class="user-post">
                    {{ user.post }}
                </div>
                <div class="actions">
                    <ButtonItem icon="pi pi-cog" @click="handlerActionSettings" />
                    <ButtonItem
                        class="p-button-danger"
                        icon="pi pi-sign-out"
                        @click="handlerActionSignOut"
                    />
                </div>
            </OverlayPanelItem>

            <ButtonItem class="header__button__mobile-nav" @click="openMobileNavBar">
                <i v-if="isOpen" class="pi pi-times" />
                <i v-else class="pi pi-bars" />
            </ButtonItem>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import helper from "@/utils/helper";
import AvatarItem from "@/components/Avatar";

export default {
    components: { AvatarItem },
    props: {
        isOpen: {
            type: Boolean,
        },
    },
    name: "u-header",
    computed: {
        ...mapGetters({
            user: "user/stateUser",
            userRole: "role/userRole",
            userRight: "right/userRight",
        }),
    },
    methods: {
        ...mapActions({ LOGOUT: "user/LOGOUT" }),
        getImagePath(path) {
            return helper.getImagePath(path);
        },
        openUserMenu(event) {
            this.$refs.overlayPanel.toggle(event);
        },
        openMobileNavBar() {
            const el = document.querySelector("body");
            if (!this.isOpen) {
                el.classList.add("nonscroll");
            } else {
                el.classList.remove("nonscroll");
            }
            this.$emit("openSidebar");
        },
        getFirstCharFromFIO(user) {
            if (user.name && user.surname) {
                return `${user.name[0]} ${user.surname[0]}`;
            } else if (user.name) {
                return `${user.name[0]}`;
            } else {
                return `${user.surname[0]}`;
            }
        },
        async handlerActionSignOut() {
            await this.LOGOUT();
            await this.$router.push("/login");
            this.$refs.overlayPanel.hide();
        },
        handlerActionSettings() {
            this.$emit("closeSidebar");
            this.$router.push("/profile");
            this.$refs.overlayPanel.hide();
        },
    },
};
</script>

<style scoped>
header {
    display: flex;
    align-items: center;
    height: 120px;
    padding: 0 30px;
    background-color: #ffffff;
    justify-content: flex-end;
    position: fixed;
    transition: 0.2s;
    margin: 0 0 0 60px;
    width: calc(100% - 60px);
    z-index: 2;
}
.view__header-small {
    width: calc(100% - 240px);
    margin: 0 0 0 240px;
}
.header__buttons {
    display: flex;
    align-items: center;
}
</style>
