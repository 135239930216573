<template>
    <div class="organization__wrapper-item u-block-group-item">
        <LoaderItem v-if="loading" />
        <div v-else class="organization__item u-list-data u-component u-list-responsive-scroll">
            <div class="organization__header u-list-header">
                <h2 class="u-title__page">Карточка компании</h2>
                <ButtonItem
                    style="min-width: 160px"
                    v-if="isIAmManager() && isMyOrganization()"
                    icon="pi pi-briefcase"
                    label="Редактировать"
                    @click="showModal('cardOrganizationModal')"
                />
            </div>
            <div class="organization__body u-list-body">
                <div class="organization__body__actions">
                    <AvatarItem
                        :data-avatar-props="dataOrganization.OrganizationPhoto"
                        :data-props="dataOrganization"
                    />
                    <ButtonItem
                        v-if="isIAmManager() && isMyOrganization()"
                        v-tooltip.top="'Просмотреть список документов'"
                        icon="pi pi-file"
                        @click="showModal('documentListModal')"
                    />
                </div>
                <CardOrganization
                    v-if="
                        Number(dataOrganization.Organization.org_type) === 1 ||
                        Number(dataOrganization.Organization.org_type) === 2
                    "
                    :data-organization="dataOrganization"
                />
            </div>
            <ListOrganizationDocumentsModal
                v-if="displayModal === 'documentListModal' && isIAmManager() && isMyOrganization()"
                :isActive="displayModal === 'documentListModal'"
                :organization-data="dataOrganization"
                :organization_id="organization_id"
                @close="closeModal"
                @error="$emit('error', $event)"
            />
            <CardOrganizationModal
                v-if="
                    displayModal === 'cardOrganizationModal' && isIAmManager() && isMyOrganization()
                "
                :data-organization-props="dataOrganization"
                :isActive="
                    displayModal === 'cardOrganizationModal' &&
                    (Number(dataOrganization.Organization.org_type) === 1 ||
                        Number(dataOrganization.Organization.org_type) === 2)
                "
                @close="closeModal"
                @handlerDeletePhoto="handlerDeletePhoto"
                @submitHandler="editCardModal"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { mapGetters } from "@/store/wrapper_store";
import rightHelper from "@/utils/rightHelper";
import LoaderItem from "@/components/Loader/index.vue";
import AvatarItem from "@/components/Avatar/index.vue";
import CardOrganization from "@/modules/Organization/components/CardOrganization/CardOrganization.vue";
import CardOrganizationModal from "@/modules/Organization/components/OrganizationModal/CardOrganizationModal.vue";
import { deleteOrganizationPhotoAPI } from "@/modules/Organization/api/deleteOrganizationPhotoAPI";
import { getOrganizationDataApi } from "@/modules/Organization/api/getOrganizationDataApi";
import { editOrganizationApi } from "@/modules/Organization/api/editOrganizationApi";
import { ListOrganizationDocumentsModal } from "@/modules/ListDocuments/";

// emits and props
const emit = defineEmits(["error"]);
const props = defineProps({
    organization_id: {
        type: [String, Number],
        required: true,
    },
});

// store
const { stateUser } = mapGetters("user");

// data
let dataOrganization = ref({});
const displayModal = ref("");
const loading = ref(true);

onMounted(async () => {
    try {
        loading.value = true;
        await getOrganizationData();
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
});

/**
 * Обработчик события удаления фотографии
 * @param organization объект события
 */
const handlerDeletePhoto = async (organization) => {
    try {
        loading.value = true;
        await deleteOrganizationPhotoAPI(organization.id);
        await getOrganizationData();
    } catch (e) {
        emit("error", e);
    } finally {
        loading.value = false;
    }
};

/**
 * Проверка есть ли у пользователя, под которым прошла авторизация, правда доступа - управляющий
 */
const isIAmManager = () => {
    return rightHelper.isIAmManager(stateUser.value);
};

/**
 * Проверка принадлежит ли организация пользователю
 */
const isMyOrganization = () => props.organization_id === stateUser.value.organization;

/**
 * Получение данных об организации
 */
const getOrganizationData = async () => {
    const { content } = await getOrganizationDataApi(props.organization_id);
    dataOrganization.value = content;
};

/**
 * Обработчик редактирование информации об организации
 * @param {Object} formData - объект FormData
 */
const editCardModal = async (formData) => {
    try {
        loading.value = true;

        await editOrganizationApi(formData);
        await getOrganizationData();
    } finally {
        loading.value = false;
    }
};

/**
 * Открытие модального окна по имени
 * @param nameModal - имя модального окна
 */
const showModal = (nameModal) => {
    displayModal.value = nameModal;
};

/**
 * Закрытие модального окна
 */
const closeModal = () => {
    displayModal.value = "";
};
</script>

<style scoped>
.organization__body__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
