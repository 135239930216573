function validatePassword(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);
}

/**
 * Валидация на наличие букв. Валидация не проходит если в поле нет ни одной буквы
 * @param value - валидируемое значение
 * @returns {boolean}
 */
function validateSymbol(value) {
    if (value && value.length) {
        for (let i = 0; i < value.length; i++) {
            let char = value.charAt(i);
            if (/[А-ЯA-Zа-яa-z]/.test(char)) {
                return true;
            }
        }
        return false;
    }
    return true;
}
function validateSymbolAndNumber(value) {
    if (value && value.length) {
        for (let i = 0; i < value.length; i++) {
            let char = value.charAt(i);
            if (/[А-ЯA-Zа-яa-z]/.test(char) || /[0-9]/.test(char)) {
                return true;
            }
        }
        return false;
    }
    return true;
}
function validatePosition(value) {
    return /^[?!,.а-яА-ЯёЁ0-9\s]+$/.test(value);
}
// проверяет на 15 символов после запятой
function validateNumbersAfterDot(value) {
    if (value && value.indexOf(".") > -1) {
        return value.split(".")[1].length < 15;
    } else {
        return true;
    }
}

export {
    validatePassword,
    validatePosition,
    validateSymbol,
    validateSymbolAndNumber,
    validateNumbersAfterDot,
};
