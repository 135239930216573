export default [
    {
        path: "/Invite/user/:key",
        name: "ProfileInviteView",
        component: () => import("@/pages/InvitePage/ProfileInvitePage.vue"),
        meta: {
            requiresAuth: false,
            layout: "Invite",
        },
        props: true,
    },
    {
        path: "/Invite/org/:key",
        name: "OrganizationInviteView",
        component: () => import("@/pages/InvitePage/OrganizationInvitePage.vue"),
        meta: {
            requiresAuth: false,
            layout: "Invite",
        },
        props: true,
        children: [
            {
                path: "",
                name: "RegistrationOrganization",
                component: () =>
                    import(
                        "@/modules/Invite/components/CreateOrganizationForm/CreateOrganizationForm.vue"
                    ),
                props: true,
            },
            {
                path: "employee",
                name: "RegistrationEmployee",
                component: () =>
                    import("@/modules/Invite/components/CreateEmployeeForm/CreateEmployeeForm.vue"),
                props: true,
            },
        ],
    },
];
