import axios from "axios";
import store from "../store";
import { promiseModal } from "@/components/Auth/promiseModal";

let isRefreshing = false;
let failedQueue = []; // Массив в который попадают promise from catch

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const interceptorsError = async (error) => {
    const isAuthenticated = store.getters["user/isAuthenticated"];
    if (error.response && error.response.status === 401 && isAuthenticated) {
        const originalRequest = error.config;

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            })
                .then(() => {
                    return axios(originalRequest);
                })
                .catch((err) => {
                    return err;
                });
        } else {
            isRefreshing = true;

            await store.dispatch("user/LOGOUT");

            return new Promise((resolve, reject) => {
                promiseModal()
                    .then((result) => {
                        processQueue(null, true);
                        if (result === "success") {
                            resolve(axios(originalRequest));
                        }
                    })
                    .catch((err) => {
                        processQueue(err, false);
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }
    } else {
        console.log("error - ", error);
        return Promise.reject(error);
    }
};
