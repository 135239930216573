import { sendPost } from "@/api";

export async function uploadOrganizationDocumentsApi(documentsInfo) {
    try {
        return await sendPost("organization/upload_doc", documentsInfo);
    } catch (error) {
        console.log("ERROR!!! - uploadOrganizationDocumentsApi", error);
        throw error;
    }
}
