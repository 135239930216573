import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
/*UI Components*/
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import PanelMenu from "primevue/panelmenu";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Menu from "primevue/menu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Breadcrumb from "primevue/breadcrumb";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ProgressBar from "primevue/progressbar";
import TabMenu from "primevue/tabmenu";
import Badge from "primevue/badge";
import ProgressSpinner from "primevue/progressspinner";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";
import Card from "primevue/card";
import Steps from "primevue/steps";
import Chip from "primevue/chip";
import RadioButton from "primevue/radiobutton";
import Password from "primevue/password";
import Galleria from "primevue/galleria";
import ScrollPanel from "primevue/scrollpanel";
import AutoComplete from "primevue/autocomplete";
import Listbox from "primevue/listbox";

// import Maska from "maska";
import { vMaska } from "maska";
import InputNumber from "primevue/inputnumber";
import OverlayPanel from "primevue/overlaypanel";
import Carousel from "primevue/carousel";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

/*CSS*/
import "primevue/resources/themes/mdc-light-indigo/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
// import "./assets/style/normalize.css";
import "./assets/style/main.scss";
import "./assets/style/media.scss";
import "./assets/style/tailwind-output.css";
import Fieldset from "primevue/fieldset";

const app = createApp(App);
app.use(PrimeVue, {
    locale: {
        dayNames: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
        ],
        dayNamesShort: ["Воскр", "Пон", "Втор", "Ср", "Чет", "Пят", "Суб"],
        dayNamesMin: ["Вскр", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        firstDayOfWeek: 1,
        monthNames: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ],
        monthNamesShort: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        dateFormat: "mm/dd/yy",
        passwordPrompt: "Введите пароль",
        emptyFilterMessage: "Результат не найден",
        emptyMessage: "Нет доступных опций",
        accept: "Да",
        reject: "Нет",
        choose: "Выбрать",
        upload: "Загрузить",
        cancel: "Отменить",
    },
});

app.component("ButtonItem", Button);
app.component("ToastItem", Toast);
app.component("PanelMenu", PanelMenu);
app.component("MenuItem", Menu);
app.component("DataTableItem", DataTable);
app.component("DropdownItem", Dropdown);
app.component("ColumnItem", Column);
app.component("DialogItem", Dialog);
app.component("SidebarItem", Sidebar);
app.component("SkeletonItem", Skeleton);
app.component("CheckboxItem", Checkbox);
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("CalendarItem", Calendar);
app.component("DividerItem", Divider);
app.component("ConfirmDialog", ConfirmDialog);
app.component("BreadcrumbItem", Breadcrumb);
app.component("MultiSelect", MultiSelect);
app.component("AccordionItem", Accordion);
app.component("AccordionTabItem", AccordionTab);
app.component("ProgressBarItem", ProgressBar);
app.component("TabMenuItem", TabMenu);
app.component("BadgeItem", Badge);
app.component("ProgressSpinnerItem", ProgressSpinner);
app.component("FileUploadItem", FileUpload);
app.component("TextareaItem", Textarea);
app.component("CardItem", Card);
app.component("StepsItem", Steps);
app.component("ChipItem", Chip);
app.component("RadioButtonItem", RadioButton);
app.component("PasswordItem", Password);
app.component("OverlayPanelItem", OverlayPanel);
app.component("CarouselItem", Carousel);
app.component("TabViewItem", TabView);
app.component("TabPanelItem", TabPanel);
app.component("GalleriaItem", Galleria);
app.component("ScrollPanelItem", ScrollPanel);
app.component("AutoComplete", AutoComplete);
app.component("FieldsetItem", Fieldset);
app.component("ListBoxItem", Listbox);

app.directive("tooltip", Tooltip);
app.directive("maska", vMaska);

app.use(store)
    .use(router)
    .use(ToastService)
    .use(ConfirmationService)
    // .use(Maska)
    .mount("#app");
