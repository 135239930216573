import { sendPut } from "@/api";
import store from "@/store";

export async function sendDecisionForInviteAPI(data) {
    try {
        const response = await sendPut("invite/decision_for_invite", data);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Решение по приглашению принято",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - sendDecisionForInviteAPI", error);
        throw error;
    }
}
