import { sendGet, sendPost } from "@/api";
import store from "@/store";

async function getAll() {
    try {
        const { content } = await sendGet("licenses");
        return content;
    } catch (error) {
        console.log("ERROR!!! - getLicense", error);
        throw error;
    }
}

async function getActivated() {
    try {
        const { content } = await sendGet("licenses/activated");
        return content;
    } catch (error) {
        console.log("ERROR!!! - getActivated", error);
        throw error;
    }
}

async function getAvailable() {
    try {
        const { content } = await sendGet("licenses/available");
        return content;
    } catch (error) {
        console.log("ERROR!!! - getAvailable", error);
        throw error;
    }
}

async function activateLicense(licenseKey, loading = {}) {
    loading.value = true;
    try {
        const { content } = await sendPost(`licenses/activation?license_key=${licenseKey}`);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Активация лицензии выполнена успешно",
            type: "success",
        });
        return content;
    } catch (error) {
        console.log("ERROR!!! - getLicense", error);
        throw error;
    } finally {
        loading.value = false;
    }
}

export default { getAll, getAvailable, getActivated, activateLicense };
