<template>
    <DialogItem
        class="modal__width"
        :header="`Приглашение сотрудника`"
        v-model:visible="displayModal"
        :modal="true"
        :closable="false"
    >
        <form @submit.prevent="submitForm">
            <div class="modal__input-block">
                <span class="p-float-label">
                    <InputText
                        id="recipient"
                        type="text"
                        v-model="v$.employee.recipient.$model"
                        :class="{
                            'p-invalid': v$.employee.recipient.$invalid && submitted,
                        }"
                    />
                    <label for="recipient"> <sup>*</sup>Email</label>
                </span>
                <div v-if="v$.employee.recipient.$invalid && submitted">
                    <small v-for="error in v$.employee.recipient.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <CheckboxItem
                        id="access"
                        :binary="true"
                        class="custom-checkbox"
                        v-model="employee.manager"
                    />
                    <label for="access">Управляющий</label>
                </span>
            </div>
            <DividerItem />
            <div class="modal__footer">
                <ButtonItem
                    label="Отмена"
                    icon="pi pi-times"
                    @click="closeModal"
                    class="p-button-text"
                />
                <ButtonItem label="Отправить" icon="pi pi-check" type="submit" />
            </div>
        </form>
    </DialogItem>
</template>

<script setup>
import { ref, toRefs } from "vue";
import { useValidation } from "@/modules/Employees/components/InviteEmployeeModal/hooks/useValidation";

const emit = defineEmits(["close", "submitHandler"]);
const props = defineProps({
    isActive: {
        type: Boolean,
        default: false,
    },
    organization_id: {
        type: Number,
        default: null,
    },
});

const { isActive, organization_id } = toRefs(props);
const employee = ref({
    manager: false,
    organization: organization_id.value,
});
const submitted = ref(false);
const displayModal = ref(isActive.value);
const isFormCorrect = ref(null);
const checked = ref(true);

const { v$ } = useValidation(employee);

const closeModal = () => {
    checked.value = true;
    submitted.value = false;
    emit("close");
};

const submitForm = async () => {
    submitted.value = true;
    isFormCorrect.value = await v$.value.$validate();

    if (isFormCorrect.value) {
        emit("submitHandler", employee.value);
        console.log(employee.value);
        emit("close");
    }
};
</script>
