export default [
    {
        path: "/templates",
        name: "templates",
        component: () =>
            import(/* webpackChunkName: "about" */ "@/pages/TemplatePage/TemplatesView.vue"),
        meta: {
            requiresAuth: true,
            layout: "Default",
        },
    },
];
