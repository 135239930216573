import { createStore } from "vuex";
import general from "@/store/modules/general";
import user from "@/store/modules/user";
import role from "@/store/modules/role";
import right from "@/store/modules/right";
import notifications from "@/store/modules/notifications";

export default createStore({
    modules: {
        general,
        user,
        role,
        right,
        notifications,
    },
});
