export default {
    // "a":   information
    // "e":   error
    // "w":   warn
    // "x":   exception
    isError(type) {
        return type === "e" || type === "x";
    },
    isWarning(type) {
        return type === "w";
    },
    getStackTraceError(error) {
        if (error.stack) {
            return error.stack;
        } else {
            return error;
        }
    },
};
