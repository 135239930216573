import { useConfirm } from "primevue/useconfirm";
import { blockEmployeeApi, unblockEmployeeApi } from "@/modules/Employees/api/blockEmployeeApi";

export const useConfirmBlock = (loading, dataEmployees, getEmployeesData, emit) => {
    const confirm = useConfirm();

    const handlerBlockEmployee = async (data) => {
        try {
            loading.value = true;
            await blockEmployeeApi(data.id, data);
            dataEmployees = await getEmployeesData();
        } catch (e) {
            emit("error", e);
        } finally {
            loading.value = false;
        }
    };

    const handlerUnBlockEmployee = async (data) => {
        try {
            loading.value = true;
            await unblockEmployeeApi(data.id, data);
            dataEmployees = await getEmployeesData();
        } catch (e) {
            emit("error", e);
        } finally {
            loading.value = false;
        }
    };

    const confirmBlock = (data) => {
        confirm.require({
            message: "Вы уверены что хотите заблокировать сотрудника?",
            header: "Подтверждение блокировки",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-danger",
            accept: () => {
                handlerBlockEmployee(data);
            },
            reject: () => {
                confirm.close();
            },
        });
    };

    const confirmUnblock = (data) => {
        confirm.require({
            message: "Вы уверены что хотите разблокировать сотрудника?",
            header: "Подтверждение разблокировки",
            icon: "pi pi-info-circle",
            acceptClass: "p-button-danger",
            accept: () => {
                handlerUnBlockEmployee(data);
            },
            reject: () => {
                confirm.close();
            },
        });
    };

    return { confirmBlock, confirmUnblock };
};
