import { sendGet } from "@/api";

export async function getOrganizationDataApi(id) {
    try {
        return await sendGet(`organization?id_=${id}`);
    } catch (error) {
        console.log("ERROR!!! - getOrganizationDataApi", error);
        throw error;
    }
}
