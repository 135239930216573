import { logInApi, logOutApi, whoAmIApi, isCustomerRole } from "@/api/auth";
import licenseAPI from "@/api/licenses";

export default {
    namespaced: true,
    state: {
        user: null,
        userEmail: null, // записываем, чтобы сравнить email при переавторизации пользователя (если новый пользователь, то ридерект на главную страницу)
        isAuth: localStorage.getItem("isAuth") === "true",
        isCustomer: false,
        license: null,
    },
    getters: {
        isAuthenticated: (state) => state.isAuth,
        stateUser: (state) => state.user,
        isCustomer: (state) => state.isCustomer,
        userEmail: (state) => state.userEmail,
        stateLicense: (state) => state.license,
    },
    mutations: {
        SET_AUTH(state, status) {
            state.isAuth = status;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_USER_EMAIL(state, user) {
            state.userEmail = user.email;
        },
        SET_IS_CUSTOMER(state, status) {
            state.isCustomer = status;
        },
        SET_LICENSE_INFO(state, license) {
            state.license = license;
        },
    },
    actions: {
        async LOGIN({ dispatch, commit }, user) {
            try {
                await logInApi(user);
                localStorage.setItem("isAuth", JSON.stringify(true));
                await commit("SET_AUTH", true);
                await dispatch("WHOAMI");
            } catch (e) {
                await dispatch("LOGOUT");
            }
        },
        async WHOAMI({ commit, dispatch }) {
            try {
                let { content: user } = await whoAmIApi();
                let { content: isCustomer } = await isCustomerRole(user.organization);
                let license = await licenseAPI.getAll();
                await commit("SET_IS_CUSTOMER", isCustomer);
                await commit("SET_USER", user);
                await commit("SET_USER_EMAIL", user);
                await commit("SET_LICENSE_INFO", license);
            } catch (e) {
                await dispatch("LOGOUT");
            }
        },
        async LOGOUT({ commit }) {
            localStorage.removeItem("isAuth");
            await logOutApi();
            commit("SET_AUTH", false);
            commit("SET_USER", null);
        },
        async UPDATE_USER({ commit }, user) {
            commit("SET_USER", user);
        },
    },
};
