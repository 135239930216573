<template>
    <div class="loader__block">
        <ProgressSpinnerItem />
    </div>
</template>

<script>
export default {
    name: "LoaderItem",
};
</script>
