export default {
    letters() {
        return {
            mask: "L",
            tokens: {
                L: { pattern: /[-а-яА-Яa-zA-Z\s]/, multiple: true },
            },
            postProcess: (val) => {
                let regex = /^\s| {2,}|^\s/g;
                return val.replace(regex, "");
            },
        };
    },
    password() {
        return {
            mask: "P",
            tokens: {
                P: { pattern: /^\S*$/, multiple: true },
            },
        };
    },
    spaceMask() {
        return {
            postProcess: (val) => {
                let regex = /^\s| {2,}|^\s/g;
                return val.replace(regex, "");
            },
        };
    },
    numbersWithoutSpace() {
        return {
            mask: "N",
            tokens: { N: { pattern: /[0-9]/, multiple: true } },
            postProcess: (val) => {
                let regex = /^\s| {2,}|^\s/g;
                return val.replace(regex, "");
            },
        };
    },
    floatWithoutSpace() {
        return {
            mask: "F",
            tokens: { F: { pattern: /[0-9.]/, multiple: true } },
            postProcess: (val) => {
                let regex = /^\s| {2,}|^\s/g;
                return val.replace(regex, "");
            },
        };
    },
    dateISO() {
        return {
            mask: "####-##-##",
            tokens: { DI: { pattern: /[-0-9]/ } },
            postProcess: (val) => {
                let regex = /^\s| {2,}|^\s/g;
                return val.replace(regex, "");
            },
        };
    },
};
