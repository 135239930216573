import { sendGet } from "@/api";

export async function getOrganizationDocumentsApi(id) {
    try {
        const { content } = await sendGet(`organization/organization_docs?organization_id=${id}`);
        return content;
    } catch (error) {
        console.log("ERROR!!! - getOrganizationDocumentsApi", error);
        throw error;
    }
}
