import axios from "axios";
import handlerError from "@/utils/handlerError";
import store from "@/store";
import { interceptorsError } from "@/api/interceptors";

export const baseURL = "/api";
export const baseURLImg = "";

axios.defaults.withCredentials = true;
export const api = axios.create({
    whitCredentials: true,
    baseURL: baseURL,
    header: {
        "Content-Type": "application/json",
    },
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return interceptorsError(error);
    }
);

const handlerResponse = (url, data) => {
    const { msg_type, msg } = data;

    if (handlerError.isError(msg_type)) {
        throw { msg_type: "error", msg };
    } else if (handlerError.isWarning(msg_type)) {
        throw { msg_type: "warn", msg };
    } else {
        store.commit("general/SET_LOG", {
            message: `GET - api/${url} - Выполнено`,
            type: "success",
        });
        return data;
    }
};

export const sendGet = async (url, params = {}) => {
    try {
        const response = await api.get(
            `${url}`,
            { params },
            {
                whitCredentials: true,
            }
        );
        return handlerResponse(url, response.data);
    } catch (error) {
        const { msg_type, msg } = error;

        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
            store.commit("general/SET_LOG", {
                message: `GET - api/${url} - ${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};
export const sendPost = async (url, params = {}, config = {}) => {
    try {
        const response = await api.post(`${url}`, params, config);
        return handlerResponse(url, response.data);
    } catch (error) {
        const { msg_type, msg } = error;
        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
            store.commit("general/SET_LOG", {
                message: `POST - api/${url} - ${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};

export const sendPut = async (url, params = {}) => {
    try {
        const response = await api.put(`${url}`, params);
        return handlerResponse(url, response.data);
    } catch (error) {
        const { msg_type, msg } = error;
        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
            store.commit("general/SET_LOG", {
                message: `PUT - api/${url} - ${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};

export const sendPatch = async (url, params = {}) => {
    try {
        const response = await api.patch(`${url}`, params);
        return handlerResponse(url, response.data);
    } catch (error) {
        const { msg_type, msg } = error;
        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
            store.commit("general/SET_LOG", {
                message: `PATCH - api/${url} - ${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};

export const sendDelete = async (url) => {
    try {
        const response = await api.delete(`${url}`);
        return handlerResponse(url, response.data);
    } catch (error) {
        const { msg_type, msg } = error;
        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
            store.commit("general/SET_LOG", {
                message: `DELETE - api/${url} - ${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};

export const sendGetDownload = async (url, fileName, type_file) => {
    try {
        const response = await api.get(`${url}`, {
            responseType: "blob",
        });
        /*ToDo попробовать исправить обработку ошибок для файла*/
        if (response.data.size === 0) {
            throw "error";
        } else {
            const url2 = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url2;

            if (typeof fileName !== "string") {
                throw "Не было получено имя файла для загрузки";
            }

            let downloadName = fileName;
            if (type_file && !fileName.endsWith(type_file)) {
                downloadName = `${fileName}.${type_file}`;
            }
            link.setAttribute("download", downloadName);

            document.body.appendChild(link);
            link.click();
        }
    } catch (error) {
        const { msg_type, msg } = error;
        if (msg && msg.response?.status !== 401) {
            store.commit("general/SET_MESSAGE_VIEW", {
                message: `${msg}`,
                type: `${msg_type}`,
            });
        }
        throw "network";
    }
};
