<script setup>
defineEmits(["select", "remove", "clear"]);

defineProps({
    multiple: {
        type: Boolean,
        default: true,
    },
    isFileUploaded: {
        type: String,
        default: "",
    },
    name: {
        type: String,
        default: "",
    },
    accept: {
        type: String,
        default: "",
    },
    invalidFileTypeMessage: {
        type: String,
        default: "",
    },
    fileLimit: {
        type: Number,
        default: 0,
    },
});

const formatSize = (bytes) => {
    if (bytes === 0) return "0 Б";
    const k = 1024;
    const sizes = ["Б", "КБ", "МБ", "ГБ", "ТБ"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};
</script>

<template>
    <FileUploadItem
        :name="name"
        @select="$emit(`select`, $event)"
        @remove="$emit('remove', $event)"
        @clear="$emit('clear', $event)"
        :invalid-file-type-message="invalidFileTypeMessage"
        :accept="accept"
        :file-limit="fileLimit"
        :showUploadButton="false"
        :customUpload="true"
        :multiple="multiple"
    >
        <template #content="{ files, removeFileCallback }">
            <template v-for="(file, index) of files" :key="index">
                <div class="flex justify-between items-center">
                    <div class="flex items-center">
                        <img
                            v-if="file.type.split('/')[0] === 'image'"
                            class="mr-[20px]"
                            :src="file.objectURL"
                            :alt="file.name"
                            width="50"
                        />
                        <i v-else class="pi pi-file mr-[20px] text-[2rem]" />
                        <div>
                            <div>{{ file.name }}</div>
                            <span>{{ formatSize(file.size) }}</span>
                        </div>
                    </div>
                    <ButtonItem
                        icon="pi pi-times"
                        @click="removeFileCallback()"
                        text
                        rounded
                        severity="danger"
                    />
                </div>
            </template>
        </template>
        <template #empty>
            <p>Перетащите файлы сюда, чтобы загрузить.</p>
            <small v-if="isFileUploaded === `error`">Необходимо загрузить файл</small>
        </template>
    </FileUploadItem>
</template>

<style scoped></style>
