import { sendGet } from "@/api";

async function getTypeRoleApi() {
    try {
        const response = await sendGet("role?id_=0&name=&organization=0&project=0");
        return response;
    } catch (error) {
        console.log("ERROR!!! - getTypeRoleApi", error);
        throw error;
    }
}
async function getRoleApi(organization_id, project_id, id_role = 0, name_role = "") {
    try {
        const response = await sendGet(
            `role?id_=${id_role}&name=${name_role}&organization=${organization_id}&project=${project_id}`
        );
        return response;
    } catch (error) {
        console.log("ERROR!!! - getRoleApi", error);
        throw error;
    }
}

export { getRoleApi, getTypeRoleApi };
