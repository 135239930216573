//"right.id": 1 - "Руководитель"
//"right.id": 2 - "Исполнитель"
//"right.id": 3 - "Контроль"
//"user.manager" - "Управляющий"
export default {
    isIAmManager(user) {
        // проверка - есть ли у пользователя, под которым прошла авторизация, правда доступа - управляющий
        if (user) {
            return !!user.manager;
        }
    },
    isIAmSupervisor(right_list) {
        return right_list.find((right) => right.id === 1) || false;
    },
    isIAmExecutor(right_list) {
        return right_list.find((right) => right.id === 2) || false;
    },
    isIAmControl(right_list) {
        return right_list.find((right) => right.id === 3) || false;
    },
};
