import { sendGet } from "@/api";

async function getAllNotifications(organization_id, worker_id) {
    try {
        const content = await sendGet(
            `project/notifications?organization=${organization_id}&worker=${worker_id}`
        );
        return content;
    } catch (error) {
        console.log("ERROR!!! - getAllGroupsApi", error);
        throw error;
    }
}

async function getNotificationsByProject(organization_id, worker_id, project_id) {
    try {
        const content = await sendGet(
            `project/notifications?organization=${organization_id}&worker=${worker_id}&project_id=${project_id}`
        );
        return content;
    } catch (error) {
        console.log("ERROR!!! - getAllGroupsApi", error);
        throw error;
    }
}

export { getAllNotifications, getNotificationsByProject };
