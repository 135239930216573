<template>
    <div class="modal__wrapper-login">
        <LoginItem @error="handlerError" @click="handlerSubmit" />
    </div>
</template>

<script>
import LoginItem from "@/components/Auth";
import { mapMutations } from "vuex";
export default {
    name: "LoginModal",
    components: { LoginItem },
    emits: ["click"],
    methods: {
        ...mapMutations({ SET_MESSAGE_VIEW: "general/SET_MESSAGE_VIEW" }),
        handlerError(error) {
            this.SET_MESSAGE_VIEW({
                message: `Ошибка. Не удалось загрузить данные. ${error} `,
                type: "error",
            });
        },
        handlerSubmit(statusRequest) {
            this.$emit("click", statusRequest);
        },
    },
};
</script>

<style scoped></style>
