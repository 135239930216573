<template>
    <footer></footer>
</template>

<script>
export default {
    name: "u-footer",
};
</script>

<style scoped>
footer {
    /*background-color: black;*/
    /*height: 100px;*/
}
</style>
