<template>
    <div>
        <LoaderItem v-if="loading" />
        <template v-else>
            <div class="modal__input-block">
                <span class="p-float-label">
                    <DropdownItem
                        id="meterType"
                        v-model="type_organization_id"
                        :options="type_organization"
                        @change="handlerChange"
                        optionLabel="name"
                        optionValue="id"
                    />
                    <label for="meterType"><sup>*</sup>Тип предприятия</label>
                </span>
            </div>
            <DividerItem />
            <div class="modal__footer"></div>
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getOrganizationType } from "@/modules/Invite/api/getOrganizationType";
import { typeOrganization } from "@/data/base/typeOrganization";
import LoaderItem from "@/components/Loader/index.vue";

const emit = defineEmits(["changeType"]);

const type_organization_id = ref(-1);
const type_organization = ref(typeOrganization);
const loading = ref(true);

onMounted(async () => {
    const { content } = await getOrganizationType();
    type_organization.value = content;
    loading.value = false;
});

/**
 * Генерация события смены типа организации
 */
const handlerChange = () => {
    emit("changeType", type_organization_id.value);
};
</script>
