<template>
    <div class="wrapper__form-page invite-organization-sro-form__wrapper">
        <ButtonItem
            class="invite-organization-sro-form__close-btn"
            icon="pi pi-times"
            size="small"
            @click="onClose"
        />
        <form>
            <div class="section__form">
                <span class="section__form__name">Данные по СРО</span>
                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            :id="`name_sro-${formId}`"
                            v-model="v$.sro.name.$model"
                            :class="{
                                'p-invalid': v$.sro.name.$invalid && submitted,
                            }"
                            type="text"
                            @focusout="onSubmit"
                        />
                        <label for="name_sro"><sup>*</sup>Наименование СРО</label>
                    </span>
                    <div v-if="v$.sro.name.$invalid && submitted">
                        <small v-for="error in v$.sro.name.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            :id="`state_sro-regis-${formId}`"
                            v-model="v$.sro.regis_number.$model"
                            :class="{
                                'p-invalid': v$.sro.regis_number.$invalid && submitted,
                            }"
                            type="text"
                            @focusout="onSubmit"
                        />
                        <label for="state_sro-regis"><sup>*</sup>Номер СРО</label>
                    </span>
                    <div v-if="v$.sro.regis_number.$invalid && submitted">
                        <small v-for="error in v$.sro.regis_number.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useValidation } from "@/components/AddableSroInputs/hooks/useValidation";

const props = defineProps({
    formId: {
        type: Number,
    },
    initialSro: {
        type: Array,
        required: true,
    },
});

const emits = defineEmits(["onClose", "onSubmit"]);

const submitted = ref(false);
const isFormCorrect = ref(false);
const sro = ref({
    name: "",
    regis_number: null, // регистрационный номер СРО
});

/**
 * Обновление валидации
 */
const { v$ } = useValidation(sro);

onMounted(() => {
    getInitialData();
});

const getInitialData = () => {
    if (props.initialSro[props.formId]) {
        if (props.initialSro[props.formId].id) {
            const { name, regis_number, id } = props.initialSro[props.formId];
            sro.value = { name, regis_number, id };
        } else {
            const { name, regis_number } = props.initialSro[props.formId];
            sro.value = { name, regis_number };
        }
        onSubmit();
    }
};

const onClose = () => {
    emits("onClose", props.formId);
};

/**
 * Генерация события подтверждения формы
 */
const onSubmit = async () => {
    submitted.value = true;
    isFormCorrect.value = await v$.value.$validate();

    if (isFormCorrect.value) {
        emits("onSubmit", sro.value, props.formId);
    } else {
        emits("onSubmit", false, props.formId);
    }
};
</script>

<style scoped lang="scss">
.invite-organization-sro-form__wrapper {
    position: relative;
}
.invite-organization-sro-form__close-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    color: black;
}
</style>
