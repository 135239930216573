<template>
    <main class="not-auth">
        <div class="main__content">
            <div class="login">
                <section class="login__page">
                    <router-view />
                </section>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: "AuthLayout",
};
</script>

<style scoped></style>
