import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { validateSymbol } from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";

export const useValidation = (sro) => {
    const rules = {
        sro: {
            name: {
                required: helpers.withMessage(
                    "Поле 'Наименование СРО' обязательно к заполнению",
                    required
                ),
                validateSymbol: helpers.withMessage(
                    "Поле 'Наименование СРО' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Наименование СРО' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Наименование СРО' не должно превышать 500 символов",
                    maxLength(500)
                ),
            },
            regis_number: {
                required: helpers.withMessage(
                    "Поле 'Номер СРО' обязательно к заполнению",
                    required
                ),
                minLength: helpers.withMessage(
                    "Поле 'Номер СРО' не должно быть меньше 1 символов",
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Номер СРО' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
        },
    };

    const state = {
        sro,
    };

    const v$ = useVuelidate(rules, state);

    return { v$ };
};
