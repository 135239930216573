import { email, helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export const useValidation = (employee) => {
    const rules = {
        employee: {
            recipient: {
                required: helpers.withMessage("Поле Email обязательно к заполнению", required),
                email: helpers.withMessage(
                    "Значение не является действительным адресом электронной почты",
                    email
                ),
            },
            manager: {
                required: helpers.withMessage(
                    "Поле 'Права доступа' обязательно к заполнению",
                    required
                ),
            },
        },
    };

    const v$ = useVuelidate(rules, { employee });

    return { v$ };
};
