import { sendGet, sendPost, sendPut } from "@/api";
import store from "@/store";

async function getTypeRightApi() {
    try {
        const response = await sendGet("right");
        return response;
    } catch (error) {
        console.log("ERROR!!! - getTypeRightApi", error);
        throw error;
    }
}
async function getRightProjectApi(
    worker = 0,
    work = 0,
    project = 0,
    is_for_unplanned_remarks = false
) {
    try {
        const response = await sendGet("right/project", {
            worker,
            work,
            project,
            is_for_unplanned_remarks,
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - getRightProjectApi", error);
        throw error;
    }
}
async function getRightWorkApi(worker_id = 0, work_id = 0, project_id = 0) {
    try {
        const response = await sendGet(
            `right/project?worker=${worker_id}&work=${work_id}&project=${project_id}`
        );
        return response;
    } catch (error) {
        console.log("ERROR!!! - getRightWorkApi", error);
        throw error;
    }
}
async function addUserInProjectApi(formData) {
    try {
        const response = await sendPost("right/add_few", formData);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Сотрудник приглашен в проект",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - addUserInProjectApi", error);
        throw error;
    }
}
async function updateRightUserApi(formData) {
    try {
        const response = await sendPut("right/update", formData);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Права доступа обновлены",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - updateRightUserApi", error);
        throw error;
    }
}
async function setWorkersToWorkAPI(content) {
    try {
        const response = await sendPost("right/add_to_work", content);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Сотрудники назначены на работу",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - setWorkersToWork", error);
        throw error;
    }
}
async function updateWorkersToWorksAPI(content) {
    try {
        const response = await sendPut("right/update_work", content);
        store.commit("general/SET_MESSAGE_VIEW", {
            message: "Сотрудники назначены на работу",
            type: "success",
        });
        return response;
    } catch (error) {
        console.log("ERROR!!! - setWorkersToWork", error);
        throw error;
    }
}

export {
    setWorkersToWorkAPI,
    getRightWorkApi,
    getTypeRightApi,
    getRightProjectApi,
    addUserInProjectApi,
    updateRightUserApi,
    updateWorkersToWorksAPI,
};
