<template>
    <LoaderItem v-if="loading" />
    <div v-else class="wrapper__form-page">
        <form @submit.prevent="submitForm">
            <div class="section__form">
                <span class="section__form__name">Данные по компании</span>
                <div class="modal__input-block">
                    <span class="p-float-label">
                        <AvatarItem
                            :data-avatar-props="photo"
                            :data-props="organization"
                            :type-mode="`write`"
                            @deletePhoto="handlerDeletePhoto"
                            @selectFile="handlerSelectFile"
                            @clearFile="handlerClearFile"
                            @removeFile="handlerRemoveFile"
                        />
                    </span>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="organization_name"
                            v-model="v$.organization.name.$model"
                            :class="{
                                'p-invalid': v$.organization.name.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="organization_name"><sup>*</sup>Наименование компании</label>
                    </span>
                    <div v-if="v$.organization.name.$invalid && submitted">
                        <small v-for="error in v$.organization.name.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="postal_code"
                            v-model="v$.address.postal_code.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.address.postal_code.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="postal_code"> <sup>*</sup>Почтовый индекс</label>
                    </span>
                    <div v-if="v$.address.postal_code.$invalid && submitted">
                        <small v-for="error in v$.address.postal_code.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="area"
                            v-model="v$.address.area.$model"
                            v-maska:[optionsMask.letters()]
                            :class="{
                                'p-invalid': v$.address.area.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="area"> <sup>*</sup>Область</label>
                    </span>
                    <div v-if="v$.address.area.$invalid && submitted">
                        <small v-for="error in v$.address.area.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="city"
                            v-model="v$.address.city.$model"
                            v-maska:[optionsMask.letters()]
                            :class="{
                                'p-invalid': v$.address.city.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="city"> <sup>*</sup>Город</label>
                    </span>
                    <div v-if="v$.address.city.$invalid && submitted">
                        <small v-for="error in v$.address.city.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="street"
                            v-model="v$.address.street.$model"
                            :class="{
                                'p-invalid': v$.address.street.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="street"> <sup>*</sup>Улица</label>
                    </span>
                    <div v-if="v$.address.street.$invalid && submitted">
                        <small v-for="error in v$.address.street.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="house"
                            v-model="v$.address.house.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.address.house.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="house"> <sup>*</sup>Дом</label>
                    </span>
                    <div v-if="v$.address.house.$invalid && submitted">
                        <small v-for="error in v$.address.house.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="building"
                            v-model="v$.address.building.$model"
                            :class="{
                                'p-invalid': v$.address.building.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="building">Строение/корпус</label>
                    </span>
                    <div v-if="v$.address.building.$invalid && submitted">
                        <small v-for="error in v$.address.building.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="office"
                            v-model="v$.address.office.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.address.office.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="office">Офис</label>
                    </span>
                    <div v-if="v$.address.office.$invalid && submitted">
                        <small v-for="error in v$.address.office.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <DropdownItem
                            id="phone_template_id"
                            v-model="v$.organization.phone_template_id.$model"
                            :class="{
                                'p-invalid':
                                    v$.organization.phone_template_id.$invalid && submitted,
                            }"
                            :filter="true"
                            :showClear="true"
                            :options="countriesList"
                            optionLabel="name"
                            optionValue="id"
                            @change="handlerChangeCountry(organization.phone_template_id)"
                        />
                        <label for="phone_template_id"
                            >{{
                                screenSize === "mobile"
                                    ? "Код страны"
                                    : "Шаблон для номера телефона по стране"
                            }}
                        </label>
                    </span>
                    <div v-if="v$.organization.phone_template_id.$invalid && submitted">
                        <small
                            v-for="error in v$.organization.phone_template_id.$errors"
                            :key="error.$uid"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="phone"
                            v-model="v$.organization.phone.$model"
                            v-maska
                            :class="{
                                'p-invalid': v$.organization.phone.$invalid && submitted,
                            }"
                            :data-maska="[`${phone_mask}`]"
                            type="text"
                        />
                        <label for="phone"> <sup>*</sup>Телефон</label>
                    </span>
                    <div v-if="v$.organization.phone.$invalid && submitted">
                        <small v-for="error in v$.organization.phone.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="fax"
                            v-model="v$.organization.fax.$model"
                            v-maska
                            :class="{
                                'p-invalid': v$.organization.fax.$invalid && submitted,
                            }"
                            :data-maska="[`${phone_mask}`]"
                            type="text"
                        />
                        <label for="fax">Факс</label>
                    </span>
                    <div v-if="v$.organization.fax.$invalid && submitted">
                        <small v-for="error in v$.organization.fax.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block" v-if="organizationType === 1">
                    <span class="p-float-label">
                        <InputText
                            id="msrn"
                            v-model="v$.organization.msrn.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.organization.msrn.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="msrn"> <sup>*</sup>ОГРН</label>
                    </span>
                    <div v-if="v$.organization.msrn.$invalid && submitted">
                        <small v-for="error in v$.organization.msrn.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block" v-if="organizationType === 2">
                    <span class="p-float-label">
                        <InputText
                            id="msrnie"
                            v-model="v$.organization.msrnie.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.organization.msrnie.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="msrnie"> <sup>*</sup>ОГРНИП</label>
                    </span>
                    <div v-if="v$.organization.msrnie.$invalid && submitted">
                        <small v-for="error in v$.organization.msrnie.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="tin"
                            v-model="v$.organization.tin.$model"
                            v-maska:[optionsMask.numbersWithoutSpace()]
                            :class="{
                                'p-invalid': v$.organization.tin.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="tin"> <sup>*</sup>ИНН</label>
                    </span>
                    <div v-if="v$.organization.tin.$invalid && submitted">
                        <small v-for="error in v$.organization.tin.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label">
                        <InputText
                            id="state_regis"
                            v-model="v$.organization.state_regis.$model"
                            :class="{
                                'p-invalid': v$.organization.state_regis.$invalid && submitted,
                            }"
                            type="text"
                        />
                        <label for="state_regis"
                            ><sup>*</sup
                            >{{
                                screenSize === "mobile"
                                    ? "Номер СГР"
                                    : "Номер свидетельства о государственной регистрации"
                            }}</label
                        >
                    </span>
                    <div v-if="v$.organization.state_regis.$invalid && submitted">
                        <small
                            v-for="error in v$.organization.state_regis.$errors"
                            :key="error.$uid"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>

                <div class="modal__input-block">
                    <span class="p-float-label placeholder">
                        <CalendarItem
                            id="state_regis_date"
                            v-model="v$.organization.state_regis_date.$model"
                            v-maska:[optionsMask.dateISO()]
                            :class="{
                                'p-invalid': v$.organization.state_regis_date.$invalid && submitted,
                            }"
                            dateFormat="yy-mm-dd"
                            placeholder="гггг-мм-дд"
                        />
                        <label for="state_regis_date"
                            ><sup>*</sup>
                            {{
                                screenSize === "mobile"
                                    ? "Дата выдачи СГР"
                                    : "Дата выдачи свидетельства о государственной регистрации"
                            }}
                        </label>
                    </span>
                    <div v-if="v$.organization.state_regis_date.$invalid && submitted">
                        <small
                            v-for="error in v$.organization.state_regis_date.$errors"
                            :key="error.$uid"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
            </div>

            <div
                class="section__form"
                :class="{ 'section__form_closed-block': !form }"
                v-for="(form, index) in sro"
                :key="index"
            >
                <AddableSroInputs
                    v-if="form !== null"
                    :formId="index"
                    :initialSro="sro"
                    @onClose="closeSroForm"
                    @onSubmit="onSroFormSubmit"
                />
            </div>

            <ButtonItem
                id="card-organization-form__add-sro-btn"
                class="card-organization-form__add-sro-btn"
                label="Добавить СРО"
                icon="pi pi-plus"
                @click="addSroForm"
            />

            <DividerItem />
            <div class="modal__footer">
                <template v-if="$slots.button">
                    <slot name="button"></slot>
                </template>
                <ButtonItem :label="submitLabel" icon="pi pi-check" type="submit" />
            </div>
        </form>
    </div>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { useIsMobile } from "@/hooks/useIsMobile";
import masks from "@/utils/masks";
import helper from "@/utils/helper";
import LoaderItem from "@/components/Loader/index.vue";
import AvatarItem from "@/components/Avatar/index.vue";
import { useValidation } from "@/modules/Organization/components/CardOrganizationForm/hooks/useValidation";
import { getPhoneMaskApi } from "@/api/phone/getPhoneMask";
import AddableSroInputs from "@/components/AddableSroInputs/addableSroInputs";

// emits
const emit = defineEmits(["handlerDeletePhoto", "next-page"]);
// props
const props = defineProps({
    submitLabel: {
        type: String,
        default: "Сохранить",
    },
    dataOrganizationProps: {
        type: Object,
        default: null,
    },
    orgType: {
        type: [Number, null],
        default: 0,
    },
});
const { orgType, dataOrganizationProps } = toRefs(props);
const store = useStore();

// data
const organizationType = ref(
    props.dataOrganizationProps !== null
        ? Number(props.dataOrganizationProps.Organization.org_type)
        : orgType.value
);
const organization = ref({
    name: "", // - Название организации
    msrn: "", // ОГРН - для ИП не нужен, передаем с пустым значением
    msrnie: "", // - ОГРНИП - для Юр. лица не нужен, передаем с пустым значением
    tin: "", // - ИНН;
    phone: "", // - Телефон организации;
    phone_template_id: null, // - id маски телефона
    fax: "", // - Факс организации;
    disable: false,
    state_regis: null, // свидетельство о гос регистрации
    state_regis_date: null, // дата выдачи свидетельства о гос регистрации
});
const address = ref({
    postal_code: "",
    city: "",
    area: "",
    street: "",
    house: "",
    building: "",
    office: "",
});
const sro = ref({
    name: "",
    regis_number: "",
    id: "",
});
const finalSroForm = ref({ sro: [] });
const isSroFormCorrect = ref([]);
const photo = ref({
    name: "",
    ftype: "",
});
const fileData = ref({
    file: null,
});
const loading = ref(true);
const submitted = ref(false);
const isFormCorrect = ref(null);
const countriesList = ref([]);
const phone_mask = ref("");
const optionsMask = ref(masks);
const { screenSize } = useIsMobile();

// validation
let v$ = null;

onMounted(async () => {
    try {
        countriesList.value = await getAllPhoneMask();

        if (dataOrganizationProps.value) {
            organization.value = { ...dataOrganizationProps.value.Organization };
            organization.value.state_regis_date = helper.convertDateToDateTime(
                organization.value.state_regis_date
            );
            address.value = { ...dataOrganizationProps.value.Organization.address_rel };
            sro.value = parseSroData();

            photo.value = { ...dataOrganizationProps.value.OrganizationPhoto };

            if (!organization.value.phone_template_id) {
                phone_mask.value = getDefaultMask(countriesList.value);
            } else {
                phone_mask.value = getPhoneMask(organization.value.phone_template_id);
            }
        } else {
            phone_mask.value = getDefaultMask(countriesList.value);
        }

        getValidation();
    } catch (e) {
        setError();
    } finally {
        loading.value = false;
    }
});

watch(orgType, (val) => {
    organizationType.value = val;
    submitted.value = false;
    getValidation();
});

/**
 * Обработчик события удаления фотографии
 * @param photo объект события
 */
const handlerDeletePhoto = (photo) => emit("handlerDeletePhoto", photo);

/**
 * Получение всех масок для номера телефона
 * @return {Array} массив с масками для номеров телефонов
 */
const getAllPhoneMask = async () => {
    const { content } = await getPhoneMaskApi();
    return content;
};

/**
 * Функция, которая устанавливает маску телефона по-умолчанию с значением `Россия`
 * @param {Array} listMasks - список масок для номера телефона
 * @return {string} маска для номера телефона
 */
const getDefaultMask = (listMasks) => {
    const defaultMask = listMasks.find((mask) => {
        return mask.name === "Россия";
    });
    organization.value.phone_template_id = defaultMask.id;
    return defaultMask.phone_mask;
};

/**
 * Функция, для получения маски телефона по id шаблона для номера телефона
 * @param {number} id - id шаблона для номера телефона
 * @return {string} маска для номера телефона
 */
const getPhoneMask = (id) => {
    return countriesList.value.find((el) => el.id === id).phone_mask;
};

/**
 * Обновление валидации
 */
const getValidation = () => {
    v$ = useValidation(organization, address, phone_mask, organizationType).v$;
};

/**
 * Фиксация ошибки
 */
const setError = (error) => {
    store.commit("general/SET_MESSAGE_VIEW", {
        message: `Ошибка. Не удалось загрузить данные. ${error} `,
        type: "error",
    });
};

const parseSroData = () => {
    const preparedSro = dataOrganizationProps.value.Organization.sro.map((sro) => {
        const newSro = {
            name: sro.name,
            regis_number:
                sro.type +
                "-" +
                sro.regis_number +
                "-" +
                helper.getParseDate(sro.regis_date).split(".").join(""),
            id: sro.id,
        };

        return newSro;
    });
    return preparedSro;
};

const addSroForm = () => {
    isSroFormCorrect.value.push(null);
    sro.value.push({});
};

const closeSroForm = (formId) => {
    isSroFormCorrect.value[formId] = null;
    sro.value[formId] = null;
};

const onSroFormSubmit = (sroValue, formId) => {
    if (sroValue === false) {
        isSroFormCorrect.value[formId] = false;
    } else {
        isSroFormCorrect.value[formId] = true;
        sro.value[formId] = sroValue;
    }
};

const checkSroFormCorrect = () => {
    let isSroFormValid = isSroFormCorrect.value.find((sro) => {
        return sro === false;
    });
    return isSroFormValid === false ? false : true;
};

/**
 * подготовка SRO к отправке: удаляем пустые поля
 * в случае если было создано новое SRO его ID будет равен null
 */
const prepareSroToFetch = () => {
    finalSroForm.value.sro = sro.value.filter((input) => {
        return input !== null;
    });
    finalSroForm.value.sro.forEach((sro) => {
        if (!sro.id) sro.id = null;
    });
};

/**
 * Генерация события подтверждения формы
 */
const submitForm = async () => {
    submitted.value = true;
    isFormCorrect.value = await v$.value.$validate();
    const isSroFormValid = checkSroFormCorrect();

    if (isFormCorrect.value && isSroFormValid) {
        prepareSroToFetch();
        organization.value.state_regis_date = helper.convertDateToString(
            organization.value.state_regis_date
        );

        const formData = createFormData(
            organization.value,
            address.value,
            finalSroForm.value,
            fileData.value
        );

        emit("next-page", { formData });
    }
};

/**
 * Создание объекта FormData для отправки запроса.
 * @param {Object} organization - организация
 * @param {Object} address - адрес
 * @param {Object} sro - СРО
 * @param {File} fileData - фото
 * @return {Object} formData - объект FormData
 */
const createFormData = (organization, address, sro, fileData) => {
    const formData = new FormData();

    if (fileData.file) {
        formData.append("files", fileData.file);
    }

    formData.append("organization", JSON.stringify(organization));
    formData.append("address", JSON.stringify(address));
    formData.append("sro", JSON.stringify(sro));

    return formData;
};

/**
 * Обработчик выбора файла, записывает объект файла в file
 * @param event - событие выбора файла
 */
const handlerSelectFile = (event) => {
    fileData.value.file = event.files[0];
};

const handlerRemoveFile = (event) => {
    fileData.value.file = event.files;
};

const handlerClearFile = () => {
    fileData.value.file = [];
};

/**
 * Обработчик смены шаблона телефона, записывает новый шаблон по стране в phone_mask.
 * После смены шаблона обновляется валидация.
 * @param {number} id - id нового шаблона для номера телефона
 */
const handlerChangeCountry = (id) => {
    if (id) {
        const phoneData = countriesList.value.find((el) => el.id === id);
        organization.value.phone = "";
        organization.value.fax = "";
        phone_mask.value = phoneData.phone_mask;
        getValidation();
    }
};
</script>

<style lang="scss" scoped>
.card-organization-form__add-sro-btn {
    margin: -20px 0 0 20px;
    display: flex;
}
.section__form {
    &_closed-block {
        display: none;
    }
}
</style>
