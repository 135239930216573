<template>
    <div>
        <TypeOrganization @changeType="handlerChangeTypeOrganization" />

        <p v-if="orgType !== null">Заполнить данные по ИНН</p>
        <div v-if="orgType !== null" class="create-organization-form__container">
            <div class="input__group create-organization-form__container-input">
                <div class="modal__input-block create-organization-form__container-input-m">
                    <span class="p-float-label p-input-icon-right">
                        <i class="pi pi-spin pi-spinner" v-if="isInnLoading" />
                        <InputText
                            id="number_document"
                            v-model="v$.inn.$model"
                            :class="{
                                'p-invalid': v$.inn.$invalid && submitted,
                            }"
                            :loading="isInnLoading"
                        />
                        <label for="number_document">ИНН</label>
                        <div v-if="v$.inn.$invalid && submitted">
                            <small v-for="error in v$.inn.$errors" :key="error.$uid">
                                {{ error.$message }}
                            </small>
                        </div>
                    </span>
                </div>
            </div>
            <ButtonItem
                class="create-organization-form__container-btn"
                label="Найти"
                :disabled="!inn"
                @click="onInnSearchSubmit"
            />
        </div>

        <InviteOrganizationForm
            v-if="orgType !== null"
            :submit-label="`Далее`"
            :org-type="orgType"
            :organizationInfoByInn="organizationInfo"
            @nextPage="nextPage"
        />
    </div>
    <!-- ToDo Добавить проверку на несуществующий ID, если передали несуществующий то либо пушим на роут предыдущего шага, либо выводим кнопку назад и сообщение об ошибке-->
</template>

<script setup>
/*КОМПОНЕНТ вызывается из route.js - смотри по path*/
import { ref } from "vue";
import TypeOrganization from "@/modules/Invite/components/OrganizationType/TypeOrganization.vue";
import { useValidation } from "@/modules/Invite/components/CreateOrganizationForm/hooks/useInnValidation";
import InviteOrganizationForm from "@/modules/Invite/components/CreateOrganizationForm/InviteOrganizationForm.vue";
import { getOrganizationInfoByInn } from "@/api/organization";

const emit = defineEmits(["next-page"]);

const orgType = ref(null);
const inn = ref(null);
const submitted = ref(false);
const isInnCorrect = ref(false);
const organizationInfo = ref({});
const isInnLoading = ref(false);

const { v$ } = useValidation(inn);

/**
 * Обработчик смены типа организации
 * @param {number} org_type - выбранный тип организации
 */
const handlerChangeTypeOrganization = (org_type) => {
    orgType.value = org_type;
};

const onInnSearchSubmit = async () => {
    submitted.value = true;
    isInnCorrect.value = await v$.value.$validate();

    if (isInnCorrect.value) {
        organizationInfo.value = await getOrganizationInfoByInn(inn.value, isInnLoading);
    }
};

/**
 * Передача события подтверждения формы родителю
 * @param {Object} event - данные из формы
 */
const nextPage = (event) => {
    event.data.organization.org_type = orgType.value;
    emit("next-page", event);
};
</script>

<style scoped lang="scss">
.create-organization-form__container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;

    &-btn {
        display: flex;
        margin-top: 10px;
    }
    &-input {
        align-self: center;

        &-m {
            margin: 5px 0;
        }
    }
}
</style>
