<template>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Наименование компании:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.name }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Почтовый индекс:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.postal_code }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Область:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.area }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Город:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.city }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Улица:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.street }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Дом:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.house }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Строение/корпус:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.building }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Офис:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.address_rel?.office }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Телефон:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.phone }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">Факс:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.fax }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">
            {{ Number(dataOrganization.Organization.org_type) === 1 ? "ОГРН:" : "ОГРНИП:" }}
        </div>
        <div class="u-list-item-value u-list-item">
            {{
                Number(dataOrganization.Organization.org_type) === 1
                    ? props.dataOrganization.Organization.msrn
                    : props.dataOrganization.Organization.msrnie
            }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">ИНН:</div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.tin }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">
            Номер свидетельства о государственной регистрации:
        </div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.state_regis }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item">
            Дата выдачи свидетельства о государственной регистрации:
        </div>
        <div class="u-list-item-value u-list-item">
            {{ props.dataOrganization.Organization.state_regis_date }}
        </div>
    </div>
    <div class="u-list-item-wrapper">
        <div class="u-list-item-name u-list-item u-list-item__sro-list">СРО:</div>
        <div v-if="sroNames" class="u-list-item-value u-list-item u-list-item__sro-list">
            {{ sroNames ? sroNames.join(", ") : "" }}
        </div>
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import helper from "@/utils/helper";

const props = defineProps({
    dataOrganization: {
        type: Object,
        required: true,
    },
});

const sroNames = ref([]);

onMounted(() => {
    if (props.dataOrganization.Organization.sro.length) {
        sroNames.value = props.dataOrganization.Organization.sro.map((sro) => {
            return (
                sro.type +
                "-" +
                sro.regis_number +
                "-" +
                helper.getParseDate(sro.regis_date).split(".").join("")
            );
        });
    }
});
</script>

<style lang="scss" scoped></style>
