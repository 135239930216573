/**
 * Функция возвращающая результат склонения числительного
 * @param value - число
 * @param words - массив склоняемых слов
 * @returns {string}
 */
export const numWord = (value, words) => {
    const val = Math.abs(value) % 100;
    const num = value % 10;

    if (val > 10 && val < 20) return words[1];
    if ((num > 1 && num < 5) || num === 1) return words[0];

    return words[1];
};

/**
 * Функция возвращающая результат склонения числительного
 * @param value - число
 * @param words - массив их 3-х склоняемых слов
 * @returns {string}
 */
export const updatedNumWord = (value, words) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return words[value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5]];
};
