import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { validateSymbol } from "@/utils/validation";
import { useVuelidate } from "@vuelidate/core";

export const useValidation = (fileData) => {
    const rules = {
        fileData: {
            name: {
                required: helpers.withMessage(
                    "Поле `Наименование документа` обязательно к заполнению",
                    required
                ),
                validateSymbol: helpers.withMessage(
                    "Поле 'Наименование документа' должно содержать буквы",
                    validateSymbol
                ),
                minLength: helpers.withMessage(
                    "Поле 'Наименование документа' не должно быть меньше 2 символов",
                    minLength(2)
                ),
                maxLength: helpers.withMessage(
                    "Поле 'Наименование документа' не должно превышать 50 символов",
                    maxLength(50)
                ),
            },
        },
    };

    const v$ = useVuelidate(rules, { fileData });

    return { v$ };
};
